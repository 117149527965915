import React, { Fragment } from 'react';

export default [
  {
    label: 'Desert',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/florianstitzart"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Florian Stitz
        </a>{' '}
        for{' '}
        <a
          href="http://rheincon.page5.de/wordpress/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Rheincon 2024
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Burning Earth',
        width: 4.56,
        height: 5.12,
        imageUrl: '/terrain/desert2/burning_earth1.png',
      },
      {
        label: 'Burning Earth',
        width: 4.41,
        height: 3.12,
        imageUrl: '/terrain/desert2/burning_earth2.png',
      },
      {
        label: 'Dust Devil',
        width: 4.03,
        height: 4.03,
        imageUrl: '/terrain/desert2/dust_devil1.png',
      },
      {
        label: 'Dust Devil',
        width: 5.02,
        height: 4.96,
        imageUrl: '/terrain/desert2/dust_devil2.png',
      },
      {
        label: 'Forest',
        width: 3.95,
        height: 4.91,
        imageUrl: '/terrain/desert2/forest1.png',
      },
      {
        label: 'Forest',
        width: 5.37,
        height: 7.36,
        imageUrl: '/terrain/desert2/forest2.png',
      },
      {
        label: 'Forest',
        width: 4.66,
        height: 6.27,
        imageUrl: '/terrain/desert2/forest3.png',
      },
      {
        label: 'Hill',
        width: 6.63,
        height: 5.1,
        imageUrl: '/terrain/desert2/hill.png',
      },
      {
        label: 'Obstacle',
        width: 4.06,
        height: 0.88,
        imageUrl: '/terrain/desert2/obstacle.png',
      },
      {
        label: 'Obstruction',
        width: 4.37,
        height: 4.37,
        imageUrl: '/terrain/desert2/obstruction4.png',
      },
      {
        label: 'Quicksand',
        width: 5.29,
        height: 5.96,
        imageUrl: '/terrain/desert2/quicksand.png',
      },
      {
        label: 'Rubble',
        width: 5.9,
        height: 3.74,
        imageUrl: '/terrain/desert2/rubble1.png',
      },
      {
        label: 'Rubble',
        width: 5.92,
        height: 3.74,
        imageUrl: '/terrain/desert2/rubble2.png',
      },
      {
        label: 'Rubble',
        width: 4.14,
        height: 3.26,
        imageUrl: '/terrain/desert2/obstruction1.png',
      },
      {
        label: 'Rubble',
        width: 3.11,
        height: 4.47,
        imageUrl: '/terrain/desert2/obstruction2.png',
      },
      {
        label: 'Rubble',
        width: 7.61,
        height: 3.41,
        imageUrl: '/terrain/desert2/obstruction3.png',
      },
      {
        label: 'Shallow Water',
        width: 7.25,
        height: 3.57,
        imageUrl: '/terrain/desert2/shallow_water1.png',
      },
      {
        label: 'Shallow Water',
        width: 7.28,
        height: 3.58,
        imageUrl: '/terrain/desert2/shallow_water2.png',
      },
    ],
  },
  {
    label: 'Winter',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/florianstitzart"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Florian Stitz
        </a>{' '}
        for{' '}
        <a
          href="http://rheincon.page5.de/wordpress/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Rheincon 2024
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Dust Devil',
        width: 5.33,
        height: 5.29,
        imageUrl: '/terrain/winter/dust_devil1.png',
      },
      {
        label: 'Dust Devil',
        width: 4.27,
        height: 4.24,
        imageUrl: '/terrain/winter/dust_devil2.png',
      },
      {
        label: 'Forest',
        width: 4.77,
        height: 6.96,
        imageUrl: '/terrain/winter/forest1.png',
      },
      {
        label: 'Forest',
        width: 3.91,
        height: 4.49,
        imageUrl: '/terrain/winter/forest2.png',
      },
      {
        label: 'Forest',
        width: 8.61,
        height: 4.54,
        imageUrl: '/terrain/winter/forest3.png',
      },
      {
        label: 'Hill',
        width: 6.27,
        height: 4.52,
        imageUrl: '/terrain/winter/hill.png',
      },
      {
        label: 'Obstacle',
        width: 0.9,
        height: 4.26,
        imageUrl: '/terrain/winter/obstacle.png',
      },
      {
        label: 'Obstruction',
        width: 4.62,
        height: 4.5,
        imageUrl: '/terrain/winter/obstruction.png',
      },
      {
        label: 'Obstruction',
        width: 5.04,
        height: 5.0,
        imageUrl: '/terrain/winter/obstruction1.png',
      },
      {
        label: 'Obstruction',
        width: 7.3,
        height: 7.32,
        imageUrl: '/terrain/winter/obstruction2.png',
      },
      {
        label: 'Obstruction',
        width: 5.35,
        height: 4.41,
        imageUrl: '/terrain/winter/obstruction3.png',
      },
      {
        label: 'Obstruction',
        width: 6.11,
        height: 5.12,
        imageUrl: '/terrain/winter/obstruction4.png',
      },
      {
        label: 'Obstruction',
        width: 5.02,
        height: 5.16,
        imageUrl: '/terrain/winter/obstruction5.png',
      },
      {
        label: 'Rubble',
        width: 5.14,
        height: 3.64,
        imageUrl: '/terrain/winter/rubble.png',
      },
      {
        label: 'Trench',
        width: 3.24,
        height: 5.29,
        imageUrl: '/terrain/winter/trench.png',
      },
    ],
  },
  {
    label: 'Woods',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/florianstitzart"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Florian Stitz
        </a>{' '}
        for{' '}
        <a
          href="http://rheincon.page5.de/wordpress/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Rheincon 2024
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Dust Devil',
        width: 4.45,
        height: 4.37,
        imageUrl: '/terrain/woods/dust_devil1.png',
      },
      {
        label: 'Dust Devil',
        width: 5.46,
        height: 5.46,
        imageUrl: '/terrain/woods/dust_devil2.png',
      },
      {
        label: 'Fog',
        width: 7.94,
        height: 4.06,
        imageUrl: '/terrain/woods/fog.png',
      },
      {
        label: 'Forest',
        width: 5.83,
        height: 8.42,
        imageUrl: '/terrain/woods/forest1.png',
      },
      {
        label: 'Forest',
        width: 4.89,
        height: 7.13,
        imageUrl: '/terrain/woods/forest2.png',
      },
      {
        label: 'Forest',
        width: 4.2,
        height: 5.04,
        imageUrl: '/terrain/woods/forest3.png',
      },
      {
        label: 'Hill',
        width: 6.34,
        height: 4.68,
        imageUrl: '/terrain/woods/hill.png',
      },
      {
        label: 'Obstruction',
        width: 8.01,
        height: 5.12,
        imageUrl: '/terrain/woods/obstruction1.png',
      },
      {
        label: 'Obstruction',
        width: 4.06,
        height: 6.4,
        imageUrl: '/terrain/woods/obstruction2.png',
      },
      {
        label: 'Obstruction',
        width: 5.23,
        height: 5.23,
        imageUrl: '/terrain/woods/obstruction3.png',
      },
      {
        label: 'Obstruction',
        width: 1.86,
        height: 2.2,
        imageUrl: '/terrain/woods/obstruction4.png',
      },
      {
        label: 'Obstruction',
        width: 3.28,
        height: 3.16,
        imageUrl: '/terrain/woods/obstruction5.png',
      },
      {
        label: 'Obstruction',
        width: 2.11,
        height: 2.13,
        imageUrl: '/terrain/woods/obstruction6.png',
      },
      {
        label: 'Rubble',
        width: 5.62,
        height: 3.76,
        imageUrl: '/terrain/woods/rubble1.png',
      },
      {
        label: 'Rubble',
        width: 5.81,
        height: 3.58,
        imageUrl: '/terrain/woods/rubble2.png',
      },
      {
        label: 'Shallow Water',
        width: 8.3,
        height: 4.29,
        imageUrl: '/terrain/woods/shallow_water.png',
      },
      {
        label: 'Tall Grass',
        width: 5.69,
        height: 4.1,
        imageUrl: '/terrain/woods/tall_grass.png',
      },
    ],
  },
  {
    label: 'Village',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/florianstitzart"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Florian Stitz
        </a>{' '}
        for{' '}
        <a
          href="http://rheincon.page5.de/wordpress/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Rheincon 2024
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Burning Earth',
        width: 5.85,
        height: 4.39,
        imageUrl: '/terrain/village/burning_earth1.png',
      },
      {
        label: 'Burning Earth',
        width: 7.36,
        height: 5.37,
        imageUrl: '/terrain/village/burning_earth2.png',
      },
      {
        label: 'Crater',
        width: 6.57,
        height: 6.61,
        imageUrl: '/terrain/village/crater1.png',
      },
      {
        label: 'Crater',
        width: 5.35,
        height: 5.31,
        imageUrl: '/terrain/village/crater2.png',
      },
      {
        label: 'Fog',
        width: 9.58,
        height: 4.89,
        imageUrl: '/terrain/village/fog1.png',
      },
      {
        label: 'Forest',
        width: 8.63,
        height: 6.13,
        imageUrl: '/terrain/village/forest1.png',
      },
      {
        label: 'Forest',
        width: 5.52,
        height: 10.66,
        imageUrl: '/terrain/village/forest2.png',
      },
      {
        label: 'Forest',
        width: 6.13,
        height: 4.56,
        imageUrl: '/terrain/village/forest3.png',
      },
      {
        label: 'Obstacle',
        width: 5.27,
        height: 1.13,
        imageUrl: '/terrain/village/obstacle.png',
      },
      {
        label: 'Obstruction',
        width: 6.34,
        height: 6.52,
        imageUrl: '/terrain/village/obstruction1.png',
      },
      {
        label: 'Obstruction',
        width: 6.36,
        height: 6.54,
        imageUrl: '/terrain/village/obstruction2.png',
      },
      {
        label: 'Obstruction',
        width: 7.0,
        height: 4.96,
        imageUrl: '/terrain/village/obstruction3.png',
      },
      {
        label: 'Rubble',
        width: 7.53,
        height: 4.24,
        imageUrl: '/terrain/village/rubble1.png',
      },
      {
        label: 'Rubble',
        width: 7.53,
        height: 4.27,
        imageUrl: '/terrain/village/rubble2.png',
      },
      {
        label: 'Trench',
        width: 6.59,
        height: 4.03,
        imageUrl: '/terrain/village/trench.png',
      },
    ],
  },  
  {
    label: 'Desert',
    backgroundImageUrl: '/backgrounds/desert.jpg',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/tmsmnns"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Tomas Mennes
        </a>{' '}
        for the{' '}
        <a
          href="https://knightsmachine.com/2020/06/12/vtc-announcement/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          2020 VTC
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Burning Earth',
        width: 7.03,
        height: 7.27,
        imageUrl: '/terrain/desert/fire.png',
      },
      {
        label: 'Dense Fog',
        width: 7.03,
        height: 7.62,
        imageUrl: '/terrain/desert/smoke.png',
      },
      {
        label: 'Forest',
        width: 8.44,
        height: 7.87,
        imageUrl: '/terrain/desert/forest1.png',
      },
      {
        label: 'Forest',
        width: 9.02,
        height: 4.85,
        imageUrl: '/terrain/desert/forest2.png',
      },
      {
        label: 'Rubble',
        width: 9.8,
        height: 5.93,
        imageUrl: '/terrain/desert/structure1.png',
      },
      {
        label: 'Rubble',
        width: 5.81,
        height: 7.78,
        imageUrl: '/terrain/desert/structure2.png',
      },
      {
        label: 'Rubble',
        width: 5.98,
        height: 6.77,
        imageUrl: '/terrain/desert/structure3.png',
      },
      {
        label: 'Trench',
        width: 5.04,
        height: 3.0,
        imageUrl: '/terrain/desert/trench.png',
      },
    ],
  },  
  {
    label: 'Grasslands',
    backgroundImageUrl: '/backgrounds/grasslands.jpg',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/tmsmnns"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Tomas Mennes
        </a>{' '}
        for the{' '}
        <a
          href="https://knightsmachine.com/2020/06/12/vtc-announcement/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          2020 VTC
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Forest',
        width: 6.02,
        height: 6.0,
        imageUrl: '/terrain/grasslands/forest1.png',
      },
      {
        label: 'Forest',
        width: 9.05,
        height: 7.38,
        imageUrl: '/terrain/grasslands/forest2.png',
      },
      {
        label: 'Forest',
        width: 5.53,
        height: 7.27,
        imageUrl: '/terrain/grasslands/forest3.png',
      },
      {
        label: 'Obstruction',
        width: 7.34,
        height: 4.38,
        imageUrl: '/terrain/grasslands/structure.png',
      },
      {
        label: 'Obstacle',
        width: 7.13,
        height: 0.75,
        imageUrl: '/terrain/grasslands/wall.png',
      },
      {
        label: 'Rubble',
        width: 4.5,
        height: 4.88,
        imageUrl: '/terrain/grasslands/rubble.png',
      },
      {
        label: 'Trench',
        width: 9.47,
        height: 6.16,
        imageUrl: '/terrain/grasslands/hill1.png',
      },
      {
        label: 'Trench',
        width: 4.97,
        height: 6.16,
        imageUrl: '/terrain/grasslands/hill2.png',
      },
    ],
  },
  {
    label: 'Swamp',
    backgroundImageUrl: '/backgrounds/swamp.jpg',
    credit: (
      <Fragment>
        This terrain set was contributed by{' '}
        <a
          href="https://twitter.com/tmsmnns"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          Tomas Mennes
        </a>{' '}
        for the{' '}
        <a
          href="https://knightsmachine.com/2020/06/12/vtc-announcement/"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          2020 VTC
        </a>
        .
      </Fragment>
    ),
    terrain: [
      {
        label: 'Acid Pool',
        width: 5.55,
        height: 7.27,
        imageUrl: '/terrain/swamp/acid-pool.png',
      },
      {
        label: 'Dense Fog',
        width: 6.89,
        height: 8.63,
        imageUrl: '/terrain/swamp/cloud1.png',
      },
      {
        label: 'Dense Fog',
        width: 6.52,
        height: 8.13,
        imageUrl: '/terrain/swamp/cloud2.png',
      },
      {
        label: 'Forest',
        width: 7.88,
        height: 8.18,
        imageUrl: '/terrain/swamp/forest.png',
      },
      {
        label: 'Rubble',
        width: 4.52,
        height: 4.88,
        imageUrl: '/terrain/swamp/rubble.png',
      },
      {
        label: 'Shallow Water',
        width: 5.55,
        height: 7.27,
        imageUrl: '/terrain/swamp/water1.png',
      },
      {
        label: 'Shallow Water',
        width: 6.02,
        height: 6.0,
        imageUrl: '/terrain/swamp/water2.png',
      },
      {
        label: 'Shallow Water',
        width: 9.02,
        height: 7.41,
        imageUrl: '/terrain/swamp/water3.png',
      },
    ],
  },  
];
