import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';

const PlayerSelector = ({ currentPlayer, onPlayerChange, player1Label, player2Label, isLocked = false }) => {
    const handleChange = (event, newValue) => {
        // Make sure we only call onPlayerChange when we have a valid value
        if (newValue !== null) {
            onPlayerChange(newValue);
        }
    };

    return (
        <Tooltip title={isLocked ? "Player selection is locked" : "Choose which player you are"}>
            <span>
                <ToggleButtonGroup
                    value={currentPlayer}
                    exclusive
                    onChange={handleChange}
                    aria-label="player selection"
                    size="small"
                    disabled={isLocked}
                >
                    <ToggleButton
                        value={1}
                        aria-label="player 1"
                        style={{
                            backgroundColor: currentPlayer === 1 ? '#e3f2fd' : 'inherit',
                            fontWeight: currentPlayer === 1 ? 'bold' : 'normal',
                        }}
                    >
                        {player1Label || 'Player 1'}
                    </ToggleButton>
                    <ToggleButton
                        value={2}
                        aria-label="player 2"
                        style={{
                            backgroundColor: currentPlayer === 2 ? '#e3f2fd' : 'inherit',
                            fontWeight: currentPlayer === 2 ? 'bold' : 'normal',
                        }}
                    >
                        {player2Label || 'Player 2'}
                    </ToggleButton>
                </ToggleButtonGroup>
            </span>
        </Tooltip>
    );
};

export default PlayerSelector;
