import { getCardImage } from './CardAssets';

const suits = ['Crows', 'Rams', 'Tomes', 'Masks'];
const values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];

const StandardDeck = [];

// Create standard 52 cards
for (const suit of suits) {
  for (const value of values) {
    StandardDeck.push({
      value,
      suit,
      label: `${value} of ${suit}`,
      imageUrl: getCardImage(suit, value),      
    });
  }
}

// Add jokers
StandardDeck.push({ 
  value: 'joker', 
  suit: 'red', 
  label: 'Red Joker', 
  imageUrl: getCardImage('red', 'joker')  
});

StandardDeck.push({ 
  value: 'joker', 
  suit: 'black', 
  label: 'Black Joker', 
  imageUrl: getCardImage('black', 'joker')  
});

export default StandardDeck;
