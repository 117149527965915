/**
 * Utility functions for managing card states in the CardFlipDialog component
 */

/**
 * Gets the current player's properties based on the player number
 * @param {Object} state The current component state
 * @param {Function} syncToGameAttrs Function to sync state to game attributes
 * @param {Object} gameProps Game properties containing player labels
 * @returns {Object} The current player's properties and update methods
 */
export const getCurrentPlayerProps = (state, syncToGameAttrs, gameProps) => {
    // Use localStorage to get the current player instead of state
    const storedPlayer = localStorage.getItem(`currentPlayer_${gameProps.id}`);
    const currentPlayer = storedPlayer ? parseInt(storedPlayer, 10) : state.currentPlayer;

    if (currentPlayer === 1) {
        return {
            deck: state.player1Deck,
            discardPile: state.player1DiscardPile,
            currentCards: state.player1CurrentCards,
            handCards: state.player1HandCards,
            exilePile: state.player1ExilePile,
            revealedCards: state.player1RevealedCards,
            updateState: (deck, discard, current, hand = state.player1HandCards, exile = state.player1ExilePile, callback) => {
                const newState = {
                    player1Deck: deck,
                    player1DiscardPile: discard,
                    player1CurrentCards: current,
                    player1HandCards: hand,
                    player1ExilePile: exile
                };
                return { newState, callback };
            },
            label: gameProps ? gameProps.attrs.player1Label || 'Player 1' : 'Player 1'
        };
    } else {
        return {
            deck: state.player2Deck,
            discardPile: state.player2DiscardPile,
            currentCards: state.player2CurrentCards,
            handCards: state.player2HandCards,
            exilePile: state.player2ExilePile,
            revealedCards: state.player2RevealedCards,
            updateState: (deck, discard, current, hand = state.player2HandCards, exile = state.player2ExilePile, callback) => {
                const newState = {
                    player2Deck: deck,
                    player2DiscardPile: discard,
                    player2CurrentCards: current,
                    player2HandCards: hand,
                    player2ExilePile: exile
                };
                return { newState, callback };
            },
            label: gameProps ? gameProps.attrs.player2Label || 'Player 2' : 'Player 2'
        };
    }
};

/**
 * Gets the opponent player's properties based on the current player number
 * @param {Object} state The current component state
 * @param {Object} gameProps Game properties containing player labels
 * @returns {Object} The opponent player's properties
 */
export const getOpponentPlayerProps = (state, gameProps) => {
    // Use localStorage to get the current player instead of state
    const storedPlayer = localStorage.getItem(`currentPlayer_${gameProps.id}`);
    const currentPlayer = storedPlayer ? parseInt(storedPlayer, 10) : state.currentPlayer;

    if (currentPlayer === 1) {
        return {
            deck: state.player2Deck,
            discardPile: state.player2DiscardPile,
            currentCards: state.player2CurrentCards,
            handCards: state.player2HandCards,
            exilePile: state.player2ExilePile,
            revealedCards: state.player2RevealedCards,
            label: gameProps ? gameProps.attrs.player2Label || 'Player 2' : 'Player 2'
        };
    } else {
        return {
            deck: state.player1Deck,
            discardPile: state.player1DiscardPile,
            currentCards: state.player1CurrentCards,
            handCards: state.player1HandCards,
            exilePile: state.player1ExilePile,
            revealedCards: state.player1RevealedCards,
            label: gameProps ? gameProps.attrs.player1Label || 'Player 1' : 'Player 1'
        };
    }
};

/**
 * Creates the game state object to sync with the backend
 * @param {Object} state The current component state
 * @param {Object} gameProps Game properties
 * @param {number} newHandCards Optional new hand cards to include in the sync
 * @returns {Object} The game state object to sync
 */
export const createGameStateForSync = (state, gameProps, newHandCards = null) => {
    const now = Date.now();
    const selectedPlayer = localStorage.getItem(`currentPlayer_${gameProps.id}`);
    const playerKey = selectedPlayer ? `player${selectedPlayer}` : null;
    const isPlayerSelected = state.playerSelectionLocked && selectedPlayer;

    // Get the current game state if it exists to preserve other player's hand cards
    const currentGameState = gameProps?.attrs?.cardFlipState || {};

    return {
        selectedPlayer: selectedPlayer,
        player1: {
            deck: state.player1Deck,
            discardPile: state.player1DiscardPile,
            currentCards: state.player1CurrentCards,
            exilePile: state.player1ExilePile,
            // For player1's hand cards:
            // - If current player is player1, use the updated hand cards
            // - If current player is player2, preserve player1's hand cards from the game state
            handCards: isPlayerSelected ? 
                (playerKey === 'player1' ? 
                    (newHandCards !== null ? newHandCards : state.player1HandCards) : 
                    (currentGameState.player1?.handCards || [])
                ) : []
        },
        player2: {
            deck: state.player2Deck,
            discardPile: state.player2DiscardPile,
            currentCards: state.player2CurrentCards,
            exilePile: state.player2ExilePile,
            // For player2's hand cards:
            // - If current player is player2, use the updated hand cards
            // - If current player is player1, preserve player2's hand cards from the game state
            handCards: isPlayerSelected ? 
                (playerKey === 'player2' ? 
                    (newHandCards !== null ? newHandCards : state.player2HandCards) : 
                    (currentGameState.player2?.handCards || [])
                ) : []
        },
        // Include shared reveal dialog state with default values to prevent undefined
        sharedRevealDialogOpen: state.sharedRevealDialogOpen || false,
        sharedRevealedCards: state.sharedRevealedCards || [],
        sharedRevealPlayerNum: state.sharedRevealPlayerNum || 1, // Default to 1 to avoid undefined
        sharedRevealFromOpponent: state.sharedRevealFromOpponent || false,
        lastUpdate: now
    };
};

/**
 * Returns the revealed cards array based on the current state
 * @param {Object} state Current component state
 * @param {boolean} revealFromOpponent Whether to get opponent's revealed cards
 * @param {Object} gameProps Game properties containing the game ID
 * @returns {Array} The appropriate revealed cards array
 */
export const getRevealedCards = (state, revealFromOpponent, gameProps) => {
    // Use localStorage to get the current player instead of state
    const storedPlayer = gameProps && gameProps.id ? 
        localStorage.getItem(`currentPlayer_${gameProps.id}`) : null;
    const currentPlayer = storedPlayer ? 
        parseInt(storedPlayer, 10) : state.currentPlayer;
        
    const targetPlayerNum = revealFromOpponent ? (currentPlayer === 1 ? 2 : 1) : currentPlayer;
    return state[`player${targetPlayerNum}RevealedCards`] || [];
};