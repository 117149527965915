import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import CardsPopout from './game/CardsPopout';
import CopyGame from './game/CopyGame';
import Game from './game/Game';
import GameConfigurator from './GameConfigurator';
import Invite from './Invite';
import SignUp from './signup/SignUp';
import TokenTest from './TokenTest';

import ModulePage from './home/ModulePage';
import theme from './Palette';
import { initTracker, withTracker } from './PageTracker';
import withUser from './withUser';

initTracker();

const REDIRECTS = {
  '/malifaux': '/modules/Malifaux',
  '/breachstorm': '/modules/Breachstorm',
  '/bushido': '/modules/Bushido',
  '/guildball': '/modules/GuildBall',
  '/warmahordes': '/modules/Warmahordes',
  '/warmachine': '/modules/Warmachine',
};

export default class App extends Component {
  render() {
    return (
      <Fragment>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              {Object.entries(REDIRECTS).map(([source, dest]) => (
                <Route
                  key={source}
                  path={source}
                  render={() => <Redirect to={dest} />}
                />
              ))}
              <Route
                path="/modules/:moduleId"
                render={(props) => {
                  const WrappedComponent = withUser(withTracker(ModulePage));
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/new"
                render={(props) => {
                  const WrappedComponent = withUser(
                    withTracker(GameConfigurator)
                  );
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/tokens"
                render={(props) => {
                  const WrappedComponent = withTracker(TokenTest);
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/invite/:gameId/:inviteCode"
                render={(props) => {
                  const WrappedComponent = withUser(withTracker(Invite));
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/games/:id/cards"
                render={(props) => {
                  const WrappedComponent = withUser(withTracker(CardsPopout));
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/games/:id/copy"
                render={(props) => {
                  const WrappedComponent = withUser(withTracker(CopyGame));
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                path="/games/:id"
                render={(props) => {
                  const WrappedComponent = withUser(withTracker(Game));
                  return <WrappedComponent {...props} />;
                }}
              />
              <Route
                render={(props) => {
                  const WrappedComponent = withTracker(SignUp);
                  return <WrappedComponent {...props} />;
                }}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </Fragment>
    );
  }
}
