import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

const DiscardPile = ({ discardPile, onSendToHand, onSendToDeck, onSendToExile }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const cardHeight = 300;
  const cardWidth = 220;
  const selectionOffset = 20; // Height in px that a card moves when selected
  const buttonAreaHeight = 60; // Height reserved for the buttons area
  
  // Reverse the discard pile array to display newest cards first (on the left)
  const reversedDiscardPile = [...discardPile].reverse();
  
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    setSelectedCardIndex(-1); // Reset selection when opening dialog
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedCardIndex(-1); // Reset selection when closing dialog
  };

  const handleSelectCard = (index) => {
    setSelectedCardIndex(index === selectedCardIndex ? -1 : index);
  };

  // Convert a reversed index back to the original discardPile index
  const getOriginalIndex = (reversedIndex) => {
    return discardPile.length - 1 - reversedIndex;
  };
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="subtitle2" align="center" style={{ marginBottom: '1rem' }}>
        Discard ({discardPile.length})
      </Typography>
      
      {discardPile.length > 0 ? (
        <>
          <img 
            src={discardPile[discardPile.length - 1].imageUrl}
            alt={`Top discard: ${discardPile[discardPile.length - 1].label}`}
            style={{ 
              height: cardHeight * 0.5, 
              maxWidth: cardWidth * 0.5,
              width: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
              opacity: 0.7,
              cursor: 'pointer'
            }}
            onClick={handleOpenDialog}
          />
          <Button
            size="small"
            variant="text"
            onClick={handleOpenDialog}
            style={{ marginTop: '8px' }}
          >
            View All
          </Button>
        </>
      ) : (
        <div style={{ 
          height: cardHeight * 0.5, 
          width: cardWidth * 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed #ccc',
          borderRadius: 8 
        }}>
          <Typography variant="caption" color="textSecondary">Empty</Typography>
        </div>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Discard Pile ({discardPile.length} cards)
        </DialogTitle>
        <DialogContent sx={{ minHeight: 250 + selectionOffset + buttonAreaHeight + 'px' }}>
          {discardPile.length === 0 ? (
            <Typography variant="body1" align="center">
              No cards in discard pile
            </Typography>
          ) : (
            <Box 
              sx={{ 
                position: 'relative',
                width: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                height: 'auto',
                padding: '8px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {/* Container for the cards with extra padding at the top to accommodate selection movement */}
              <div style={{ 
                display: 'flex', 
                position: 'relative', 
                minWidth: 'min-content',
                height: 250 + selectionOffset + 'px',  // Add extra space for the upward movement
                marginBottom: '20px',
                paddingTop: selectionOffset + 'px'     // Add padding at the top for selected cards
              }}>
                {/* Display cards in horizontal overlapping style with newest cards on the left */}
                {reversedDiscardPile.map((card, index) => {
                  // Calculate offset for overlapping cards
                  const leftOffset = index * 40; // 40px offset for each card
                  const isSelected = selectedCardIndex === index;
                  
                  return (
                    <div 
                      key={`${card.label}-${index}`}
                      style={{
                        position: 'absolute',
                        left: leftOffset,
                        top: isSelected ? '0' : selectionOffset + 'px', // Position cards below the padding if not selected
                        zIndex: isSelected ? 1000 : 1000 - index, // Higher z-index for newer cards, with selected card always on top
                        transition: 'all 0.2s ease',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleSelectCard(index)}
                    >
                      <img
                        src={card.imageUrl}
                        alt={card.label}
                        title={card.label}
                        style={{
                          height: '250px',
                          borderRadius: '8px',
                          boxShadow: isSelected ? '0 0 10px 3px rgba(25, 118, 210, 0.7)' : '0 2px 8px rgba(0,0,0,0.2)',
                          border: isSelected ? '2px solid #1976d2' : 'none'
                        }}
                      />
                    </div>
                  );
                })}
                {/* This empty div ensures the container is wide enough to show all cards */}
                <div style={{ width: (discardPile.length * 40) + 150 }} />
              </div>
              
              {/* Always reserve space for the action buttons area, but only show buttons when a card is selected */}
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: buttonAreaHeight + 'px',
                padding: '10px 0'
              }}>
                {selectedCardIndex !== -1 ? (
                  <>
                    <Typography variant="subtitle2" style={{ marginRight: '16px' }}>
                      Selected: {reversedDiscardPile[selectedCardIndex].label}
                    </Typography>
                    <ButtonGroup>
                      <Button
                        variant="contained" 
                        color="primary"
                        onClick={() => {
                          // Convert back to original index
                          onSendToHand(getOriginalIndex(selectedCardIndex));
                          handleCloseDialog();
                        }}
                      >
                        To Hand
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // Convert back to original index
                          onSendToDeck(getOriginalIndex(selectedCardIndex));
                          handleCloseDialog();
                        }}
                      >
                        To Top of Deck
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          // Convert back to original index
                          onSendToExile(getOriginalIndex(selectedCardIndex));
                          handleCloseDialog();
                        }}                      
                      >
                        Exile
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Select a card to perform actions
                  </Typography>
                )}
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DiscardPile;
