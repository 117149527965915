import React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ButtonGroup from '@mui/material/ButtonGroup';

import CardDeck from './CardDeck';
import DiscardPile from './DiscardPile';
import FannedCards from './FannedCards';

const CardFlipMode = ({
    label,
    deck,
    discardPile,
    currentCards,
    handCards,
    exilePile,
    opponentProps,
    cardsToFlip,
    cardsToDraw,
    selectionSource,
    selectedCardIndex,
    selectedHandCardIndex,
    onFlipCards,
    onUndoLastFlip,
    onSelectCard,
    onSelectHandCard,
    onUseSelectedCard,
    onSetCardsToDraw,
    onDrawCardsToHand,
    onReshuffleDeck,
    onShuffleDeckOnly,
    onSendDiscardToHand,
    onSendDiscardToDeck,
    onSendDiscardToExile,
    onToggleExileDialog,
    onToggleOpponentDiscardDialog,
    getOpponentCards
}) => {
    const opponentCardData = getOpponentCards();
    
    return (
        <DialogContent style={{ padding: '16px 20px' }}>
            <Typography variant="h6" align="center" gutterBottom style={{ marginBottom: 8 }}>
                {label}'s Deck
            </Typography>

            {/* Main play area */}
            <Grid container spacing={2}>
                {/* Deck Section */}
                <Grid item xs={12} md={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CardDeck
                        deck={deck}
                        cardsToFlip={cardsToFlip}
                        onFlipCards={onFlipCards}
                    />
                </Grid>

                {/* Current Cards Section */}
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2" align="center" style={{ marginBottom: '1rem' }}>
                        Current Cards ({currentCards.length})
                        {currentCards.length > 0 && (
                            <Button
                                size="small"
                                onClick={onUndoLastFlip}
                                style={{ marginLeft: '8px' }}
                            >
                                Undo Last Flip
                            </Button>
                        )}
                    </Typography>

                    <FannedCards
                        cards={currentCards}
                        selectedIndex={selectionSource === 'current' ? selectedCardIndex : -1}
                        cardSource="current"
                        onCardClick={onSelectCard}
                        emptyMessage="No cards flipped yet"
                    />
                    <div style={{ height: '40px', textAlign: 'center', marginTop: 8 }}>
                        {selectionSource === 'current' && selectedCardIndex !== -1 && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => onUseSelectedCard(selectedCardIndex, selectedHandCardIndex, selectionSource)}
                                size="small"
                            >
                                Use Selected Card
                            </Button>
                        )}
                    </div>
                </Grid>

                {/* Opponent Cards Section */}
                <Grid item xs={12} md={3}>
                    <Typography variant="subtitle2" align="center" style={{ color: '#7b1fa2', marginBottom: '1rem' }}>
                        {opponentProps.label}'s {opponentCardData.source === 'current' ? 'Current' : 'Last'} Card{opponentCardData.cards.length > 1 ? 's' : ''}
                    </Typography>

                    <FannedCards
                        cards={opponentCardData.cards}
                        isOpponent={true}
                        cardSource={opponentCardData.source || 'current'}
                        emptyMessage={`${opponentProps.label} has no cards flipped`}
                        onCardClick={opponentCardData.source === 'discard' ? () => onToggleOpponentDiscardDialog() : null}
                    />
                </Grid>

                {/* Discard Pile Section */}
                <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <DiscardPile
                        discardPile={discardPile}
                        onSendToHand={onSendDiscardToHand}
                        onSendToDeck={onSendDiscardToDeck}
                        onSendToExile={onSendDiscardToExile}
                    />
                </Grid>
            </Grid>

            {/* Hand Cards Section */}
            <Grid container style={{ marginTop: 2 }}>
                <Grid item xs={12}>
                    <Divider style={{ margin: '8px 0' }} />

                    <Grid container>
                        {/* Draw Controls Section */}
                        <Grid item xs={12} md={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingRight: 16 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center' }}>
                                <ButtonGroup size="small" color="secondary">
                                    {[1, 2, 3].map(num => (
                                        <Button
                                            key={num}
                                            variant={cardsToDraw === num ? 'contained' : 'outlined'}
                                            onClick={() => onSetCardsToDraw(num)}
                                            style={{ minWidth: '32px' }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={onDrawCardsToHand}
                                    size="small"
                                    fullWidth
                                >
                                    Draw
                                </Button>
                                <Button
                                    onClick={onReshuffleDeck}
                                    color="primary"
                                    variant="text"
                                    size="small"
                                >
                                    Reshuffle All
                                </Button>
                                <Button
                                    onClick={onShuffleDeckOnly}
                                    color="primary"
                                    variant="text"
                                    size="small"
                                >
                                    Shuffle Deck
                                </Button>
                            </div>
                        </Grid>

                        {/* Hand Cards Section */}
                        <Grid item xs={12} md={8}>
                            <Typography variant="subtitle1" align="center" style={{ color: '#ff9800', marginBottom: '1rem' }}>
                                {label}'s Hand ({handCards.length})
                            </Typography>

                            <FannedCards
                                cards={handCards}
                                selectedIndex={selectionSource === 'hand' ? selectedHandCardIndex : -1}
                                cardSource="hand"
                                onCardClick={onSelectHandCard}
                                emptyMessage="No cards in hand"
                            />
                            <div style={{ height: '40px', textAlign: 'center', marginTop: 16 }}>
                                {selectionSource === 'hand' && selectedHandCardIndex !== -1 && (
                                    <Button
                                        color="warning"
                                        variant="contained"
                                        onClick={() => onUseSelectedCard(selectedCardIndex, selectedHandCardIndex, selectionSource)}
                                        size="small"
                                    >
                                        Use Selected Card
                                    </Button>
                                )}
                            </div>
                        </Grid>

                        {/* Exile Section */}
                        <Grid item xs={12} md={2}>
                            <Typography variant="subtitle1" align="center" style={{ color: '#9c27b0', marginBottom: 8 }}>
                                Exile ({exilePile.length})
                            </Typography>
                            <FannedCards
                                cards={exilePile}
                                cardSource="exile"
                                selectedIndex={-1}
                                style={{ transform: 'scale(0.5)' }}
                                maxCards={4}
                                emptyMessage="No exiled cards"
                                onCardClick={onToggleExileDialog}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default CardFlipMode;