import React, { Component } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Dialog from '../../../../WTDialog';
import { createShuffledDecks, shuffleDeck } from './utils/cardUtils';
import {
    getCurrentPlayerProps as getPlayerProps,
    getOpponentPlayerProps as getOpponentProps,
    createGameStateForSync,
    getRevealedCards
} from './utils/cardStateManager';
import {
    handleFlipCards,
    handleDrawCardsToHand,
    handleUseSelectedCard,
    handleRevealCards
} from './utils/cardActions';
import CardFlipMode from './components/CardFlipMode';
import PlayerSelector from './components/PlayerSelector';
import ExileDialog from './components/ExileDialog';
import RevealMode from './components/RevealMode';
import PlayerSelectionDialog from './components/PlayerSelectionDialog';
import OpponentDiscardDialog from './components/OpponentDiscardDialog';

export default class CardFlipDialog extends Component {
    constructor(props) {
        super(props);
        // Initialize wtApp if it doesn't exist
        if (!window.wtApp) {
            window.wtApp = new EventTarget();
        }
        this.state = {
            // Player 1 state
            player1Deck: [],
            player1DiscardPile: [],
            player1CurrentCards: [],
            player1HandCards: [], // Hand cards remain private to each player
            player1ExilePile: [], // Exile pile for player 1
            player1RevealedCards: [], // State for revealed cards

            // Player 2 state
            player2Deck: [],
            player2DiscardPile: [],
            player2CurrentCards: [],
            player2HandCards: [], // Hand cards remain private to each player
            player2ExilePile: [], // Exile pile for player 2
            player2RevealedCards: [], // State for revealed cards

            // Shared state
            currentPlayer: 1,
            playerSelectionLocked: false,
            pendingPlayerSelection: null, // Track pending selection before confirmation
            selectedCardIndex: -1,
            selectedHandCardIndex: -1,
            cardsToFlip: 1,
            cardsToDraw: 1,
            cardsToReveal: 1, // State for number of cards to reveal
            revealFromOpponent: false, // State to track which deck to reveal from
            selectionSource: null,
            revealedCardSelections: [], // Array to track what to do with each revealed card

            // Discard pile dialog state
            isDiscardDialogOpen: false,
            selectedDiscardIndex: -1,

            // Sync state
            lastSyncTime: 0,
            lastFlippedCards: [], // Track the most recently flipped cards for undo

            isRevealMode: false,

            // Exile dialog state
            isExileDialogOpen: false,
            selectedExileIndex: -1,

            // Discard pile dialog states
            isDiscardDialogOpen: false,
            selectedDiscardIndex: -1,
            isOpponentDiscardDialogOpen: false, // New state for opponent discard dialog
            
            // Shared reveal dialog state
            sharedRevealDialogOpen: false,
            sharedRevealedCards: [],
            sharedRevealPlayerNum: null,
            sharedRevealFromOpponent: false
        }
    }

    componentDidMount() {
        this.initializeDecks();

        // Set up regular sync check - helpful for multiplayer sync issues
        this.syncInterval = setInterval(() => {
            if (this.state.playerSelectionLocked) {
                this.syncFromGameAttrs();
            }
        }, 2000);
    }

    componentWillUnmount() {
        // Clear the interval when component unmounts
        if (this.syncInterval) {
            clearInterval(this.syncInterval);
        }
    }

    componentDidUpdate(prevProps) {
        // Check if game props have been updated (from another player's actions)
        if (this.props.game &&
            this.props.game.attrs &&
            this.props.game.attrs.cardFlipState &&
            this.props.game.attrs.cardFlipState.lastUpdate !== this.state.lastSyncTime) {
            this.syncFromGameAttrs();
        }
    }

    initializeDecks = () => {
        // Check if the game already has card state saved
        const gameCardState = this.props.game?.attrs?.cardFlipState;

        if (gameCardState) {
            // Game already has state - load it
            this.setState({
                player1Deck: gameCardState.player1.deck || [],
                player1DiscardPile: gameCardState.player1.discardPile || [],
                player1CurrentCards: gameCardState.player1.currentCards || [],
                player1HandCards: gameCardState.player1.handCards || [],
                player1ExilePile: gameCardState.player1.exilePile || [],
                player1RevealedCards: [],

                player2Deck: gameCardState.player2.deck || [],
                player2DiscardPile: gameCardState.player2.discardPile || [],
                player2CurrentCards: gameCardState.player2.currentCards || [],
                player2HandCards: gameCardState.player2.handCards || [],
                player2ExilePile: gameCardState.player2.exilePile || [],
                player2RevealedCards: [],

                lastSyncTime: gameCardState.lastUpdate
            });
        } else {
            // No saved state - create new decks
            const { player1Deck, player2Deck } = createShuffledDecks();

            this.setState({
                player1Deck,
                player1DiscardPile: [],
                player1CurrentCards: [],
                player1HandCards: [],
                player1ExilePile: [],
                player1RevealedCards: [],

                player2Deck,
                player2DiscardPile: [],
                player2CurrentCards: [],
                player2HandCards: [],
                player2ExilePile: [],
                player2RevealedCards: [],
                selectedCardIndex: -1,
                selectedHandCardIndex: -1,
                selectionSource: null,
            }, () => {
                // Save initial state to game.attrs
                this.syncToGameAttrs();
            });
        }
    }

    syncToGameAttrs = (newHandCards = null) => {
        if (!this.props.game || !this.props.game.update) return;

        // Only sync if player selection is locked
        if (!this.state.playerSelectionLocked) return;

        const now = Date.now();
        // Check if there's been a more recent update from another player
        if (this.props.game.attrs.cardFlipState && 
            this.props.game.attrs.cardFlipState.lastUpdate > this.state.lastSyncTime) {
            // If there's a more recent update, sync from game attrs first
            this.syncFromGameAttrs();
            // Then return, we'll do our update in the next tick after state is updated
            setTimeout(() => this.syncToGameAttrs(newHandCards), 100);
            return;
        }

        const cardFlipState = createGameStateForSync(this.state, this.props.game, newHandCards);

        // Update the game state
        this.props.game.update({
            ...this.props.game.attrs,
            cardFlipState
        });

        // Update local sync timestamp
        this.setState({ lastSyncTime: now });
    }

    syncFromGameAttrs = () => {
        // Get cardFlipState from game attrs
        const gameCardState = this.props.game?.attrs?.cardFlipState;
        if (!gameCardState) return;

        // Only update if the game state is newer
        if (gameCardState.lastUpdate <= this.state.lastSyncTime) return;

        // Get the current player from localStorage
        const selectedPlayer = localStorage.getItem(`currentPlayer_${this.props.game.id}`);
        
        // Update local state from game state, preserving the current player's hand cards
        const updatedState = {
            player1Deck: gameCardState.player1?.deck || [],
            player1DiscardPile: gameCardState.player1?.discardPile || [],
            player1CurrentCards: gameCardState.player1?.currentCards || [],
            player1ExilePile: gameCardState.player1?.exilePile || [],
            
            player2Deck: gameCardState.player2?.deck || [],
            player2DiscardPile: gameCardState.player2?.discardPile || [],
            player2CurrentCards: gameCardState.player2?.currentCards || [],
            player2ExilePile: gameCardState.player2?.exilePile || [],
            
            // Sync shared reveal dialog state
            sharedRevealDialogOpen: gameCardState.sharedRevealDialogOpen || false,
            sharedRevealedCards: gameCardState.sharedRevealedCards || [],
            sharedRevealPlayerNum: gameCardState.sharedRevealPlayerNum,
            sharedRevealFromOpponent: gameCardState.sharedRevealFromOpponent || false,

            lastSyncTime: gameCardState.lastUpdate
        };

        // Only update our own hand cards from game state, preserve the other player's hand cards
        if (selectedPlayer === "1") {
            updatedState.player1HandCards = gameCardState.player1?.handCards || [];
            updatedState.player2HandCards = this.state.player2HandCards; // Preserve player2 hand cards
        } else if (selectedPlayer === "2") {
            updatedState.player2HandCards = gameCardState.player2?.handCards || [];
            updatedState.player1HandCards = this.state.player1HandCards; // Preserve player1 hand cards
        } else {
            // If no player is selected, just use what's in the game state
            updatedState.player1HandCards = gameCardState.player1?.handCards || [];
            updatedState.player2HandCards = gameCardState.player2?.handCards || [];
        }

        this.setState(updatedState);
    }

    getCurrentPlayerProps = () => {
        return getPlayerProps(this.state, this.syncToGameAttrs, this.props.game);
    }

    getOpponentPlayerProps = () => {
        return getOpponentProps(this.state, this.props.game);
    }

    changePlayer = (newPlayer) => {
        // Only allow changing player if the selection isn't locked yet
        if (newPlayer !== null && !this.state.playerSelectionLocked) {
            localStorage.setItem(`currentPlayer_${this.props.game.id}`, newPlayer);

            this.setState({
                currentPlayer: newPlayer,
                // Set pending selection but don't lock yet
                pendingPlayerSelection: newPlayer
            });
        }
    }

    confirmPlayerSelection = () => {
        // Lock in the current player selection
        localStorage.setItem(`currentPlayer_${this.props.game.id}`, this.state.currentPlayer);

        this.setState({
            playerSelectionLocked: true,
            pendingPlayerSelection: null, // TODO: Remove this if not needed
            selectedCardIndex: -1,
            selectedHandCardIndex: -1,
            selectionSource: null // TODO: might remove as well            
        }, () => {
            // Sync to game attrs after player selection is confirmed
            this.syncToGameAttrs();
        });
    }

    setCardsToFlip = (num) => {
        this.setState({ cardsToFlip: num });
    }

    setCardsToDraw = (num) => {
        this.setState({ cardsToDraw: num });
    }

    setCardsToReveal = (num) => {
        this.setState({ cardsToReveal: parseInt(num, 10) });
    }

    toggleRevealTarget = () => {
        this.setState(prevState => ({ revealFromOpponent: !prevState.revealFromOpponent }));
    }

    toggleRevealMode = () => {
        // Check if there are still revealed cards that need decisions
        const { player1RevealedCards, player2RevealedCards } = this.state;
        // Get currentPlayer from localStorage instead of state
        const currentPlayer = parseInt(localStorage.getItem(`currentPlayer_${this.props.game.id}`), 10) || 1;
        const currentPlayerRevealedCards = currentPlayer === 1 ? player1RevealedCards : player2RevealedCards;
        
        // If trying to exit reveal mode and there are still revealed cards, prevent exit
        if (this.state.isRevealMode && currentPlayerRevealedCards.length > 0) {
            alert("Please make a decision (discard, exile, hand, or top deck) for all revealed cards before exiting reveal mode.");
            return;
        }
        
        this.setState(prevState => ({
            isRevealMode: !prevState.isRevealMode,
            cardsToReveal: 1,
            revealFromOpponent: false, // Reset when toggling mode
        }));
    }

    flipCards = () => {
        try {
            const result = handleFlipCards(
                this.getCurrentPlayerProps(),
                this.state.cardsToFlip,
                this.props.onLog
            );

            if (!result) return; // Exit if we shouldn't flip (e.g., exceeding 4 cards)

            const { newState, needsSecondPass, cardsRemaining } = result;
            const currentPlayerNum = this.state.currentPlayer;
            const stateUpdate = {};

            // Map the generic state keys to player-specific state keys
            if (currentPlayerNum === 1) {
                stateUpdate.player1Deck = newState.deck;
                stateUpdate.player1DiscardPile = newState.discardPile;
                stateUpdate.player1CurrentCards = newState.currentCards;
            } else {
                stateUpdate.player2Deck = newState.deck;
                stateUpdate.player2DiscardPile = newState.discardPile;
                stateUpdate.player2CurrentCards = newState.currentCards;
            }

            this.setState(stateUpdate, () => {
                this.syncToGameAttrs();

                if (needsSecondPass) {
                    setTimeout(() => {
                        const phaseTwo = this.getCurrentPlayerProps();
                        const newDeck = [...phaseTwo.deck];
                        const additionalCards = newDeck.splice(0, cardsRemaining || this.state.cardsToFlip);

                        if (currentPlayerNum === 1) {
                            this.setState({
                                player1Deck: newDeck,
                                player1CurrentCards: [...phaseTwo.currentCards, ...additionalCards]
                            }, () => {
                                this.syncToGameAttrs();
                                this.props.onLog(`${phaseTwo.label} flipped ${additionalCards.length} additional card(s) from the reshuffled deck.`);
                            });
                        } else {
                            this.setState({
                                player2Deck: newDeck,
                                player2CurrentCards: [...phaseTwo.currentCards, ...additionalCards]
                            }, () => {
                                this.syncToGameAttrs();
                                this.props.onLog(`${phaseTwo.label} flipped ${additionalCards.length} additional card(s) from the reshuffled deck.`);
                            });
                        }
                    }, 100);
                }
            });
        } catch (error) {
            alert(error.message);
        }
    }

    drawCardsToHand = () => {
        try {
            const result = handleDrawCardsToHand(
                this.getCurrentPlayerProps(),
                this.state.cardsToDraw,
                this.props.onLog
            );

            if (!result) return;

            const { newState, needsSecondPass, newHandCards } = result;
            const currentPlayerNum = this.state.currentPlayer;
            const stateUpdate = {};

            // Map the generic state keys to player-specific state keys
            if (currentPlayerNum === 1) {
                stateUpdate.player1Deck = newState.deck;
                stateUpdate.player1DiscardPile = newState.discardPile;
                stateUpdate.player1CurrentCards = newState.currentCards;
                stateUpdate.player1HandCards = newState.handCards;
            } else {
                stateUpdate.player2Deck = newState.deck;
                stateUpdate.player2DiscardPile = newState.discardPile;
                stateUpdate.player2CurrentCards = newState.currentCards;
                stateUpdate.player2HandCards = newState.handCards;
            }

            this.setState({
                ...stateUpdate,
                selectedCardIndex: -1,
                selectedHandCardIndex: -1,
                selectionSource: null
            }, () => {
                this.syncToGameAttrs(newHandCards);

                if (needsSecondPass) {
                    setTimeout(() => this.drawCardsToHand(), 100);
                }
            });
        } catch (error) {
            alert(error.message);
        }
    }

    revealCards = (isSharedReveal = false) => {
        try {
            const result = handleRevealCards(
                this.state,
                this.state.cardsToReveal,
                this.state.revealFromOpponent,
                this.props.onLog,
                isSharedReveal
            );

            // Update state based on whether this is a private or shared reveal
            if (isSharedReveal) {
                // For shared reveal, we want to show a dialog to the opponent player only
                const { revealFromOpponent } = this.state;
                // Get currentPlayer from localStorage instead of state
                const currentPlayer = parseInt(localStorage.getItem(`currentPlayer_${this.props.game.id}`), 10) || 1;
                const targetPlayerNum = revealFromOpponent ? (currentPlayer === 1 ? 2 : 1) : currentPlayer;
                
                this.setState({
                    ...result.newState,
                    // Set the shared reveal state - dialog will only be shown to opponents in the UI
                    sharedRevealDialogOpen: true,
                    sharedRevealedCards: result.newState[`player${targetPlayerNum}RevealedCards`],
                    sharedRevealPlayerNum: currentPlayer, // Using the localStorage value
                    sharedRevealFromOpponent: revealFromOpponent
                }, () => {
                    this.syncToGameAttrs();
                });
            } else {
                // For private reveal, just update the local state
                this.setState(result.newState, () => {
                    this.syncToGameAttrs();
                });
            }
        } catch (error) {
            alert(error.message);
        }
    }

    handleCloseSharedRevealDialog = () => {
        this.setState({
            sharedRevealDialogOpen: false
        }, () => {
            this.syncToGameAttrs();
        });
    }

    handleRevealedCardAction = (index, action) => {
        const { revealFromOpponent } = this.state;
        // Get currentPlayer from localStorage
        const currentPlayer = parseInt(localStorage.getItem(`currentPlayer_${this.props.game.id}`), 10) || 1;
        const targetPlayerNum = revealFromOpponent ? (currentPlayer === 1 ? 2 : 1) : currentPlayer;

        const revealedCards = this.state[`player${targetPlayerNum}RevealedCards`];
        const card = revealedCards[index];

        const newRevealedCards = [...revealedCards];
        newRevealedCards.splice(index, 1);

        const stateUpdates = {
            [`player${targetPlayerNum}RevealedCards`]: newRevealedCards
        };

        switch (action) {
            case 'discard':
                stateUpdates[`player${targetPlayerNum}DiscardPile`] = [...this.state[`player${targetPlayerNum}DiscardPile`], card];
                break;
            case 'exile':
                stateUpdates[`player${targetPlayerNum}ExilePile`] = [...this.state[`player${targetPlayerNum}ExilePile`], card];
                break;
            case 'hand':
                stateUpdates[`player${targetPlayerNum}HandCards`] = [...this.state[`player${targetPlayerNum}HandCards`], card];
                break;
            case 'deck':
                stateUpdates[`player${targetPlayerNum}Deck`] = [card, ...this.state[`player${targetPlayerNum}Deck`]];
                break;
        }

        // Get the correct player label for logging
        const playerLabel = targetPlayerNum === 1 ?
            (this.props.game?.attrs?.player1Label || "Player 1") :
            (this.props.game?.attrs?.player2Label || "Player 2");

        this.setState(stateUpdates, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${playerLabel} moved a revealed card to ${action}`);
        });
    }

    useSelectedCard = (selectedCardIndex, selectedHandCardIndex, selectionSource) => {
        const result = handleUseSelectedCard(
            this.getCurrentPlayerProps(),
            selectedCardIndex,
            selectedHandCardIndex,
            selectionSource,
            this.props.onLog
        );

        if (!result) return;

        const { newState, newHandCards } = result;
        const currentPlayerNum = this.state.currentPlayer;
        const stateUpdate = {};

        // If using a card from hand, also move current cards to discard
        if (selectionSource === 'hand') {
            const currentCards = currentPlayerNum === 1 ? this.state.player1CurrentCards : this.state.player2CurrentCards;
            
            // Note: The selected hand card is already added to the discard pile by handleUseSelectedCard
            // Just add the current cards to the discard pile
            if (currentCards.length > 0) {
                newState.discardPile = [...newState.discardPile, ...currentCards];
                newState.currentCards = []; // Clear current cards since they're moved to discard
            }
        }

        // Map the generic state keys to player-specific state keys
        if (currentPlayerNum === 1) {
            stateUpdate.player1Deck = newState.deck;
            stateUpdate.player1DiscardPile = newState.discardPile;
            stateUpdate.player1CurrentCards = newState.currentCards;
            stateUpdate.player1HandCards = newState.handCards;
        } else {
            stateUpdate.player2Deck = newState.deck;
            stateUpdate.player2DiscardPile = newState.discardPile;
            stateUpdate.player2CurrentCards = newState.currentCards;
            stateUpdate.player2HandCards = newState.handCards;
        }

        this.setState({
            ...stateUpdate,
            selectedCardIndex: -1,
            selectedHandCardIndex: -1,
            selectionSource: null
        }, () => {
            this.syncToGameAttrs(newHandCards);
        });
    }

    reshuffleDeck = () => {
        const playerLabel = this.getCurrentPlayerProps().label;

        if (!window.confirm(`Are you sure you want to reshuffle ${playerLabel}'s deck? (Cards in hand and current cards will remain where they are)`)) {
            return;
        }

        const { deck, discardPile, currentCards, handCards, updateState } = this.getCurrentPlayerProps();
        // Only include deck and discard pile in the shuffle, leave current cards where they are
        const allCards = [...deck, ...discardPile];
        const shuffledCards = shuffleDeck(allCards);

        // Keep current cards and hand cards as they are
        const result = updateState(shuffledCards, [], currentCards, handCards);

        this.setState(result.newState, () => {
            this.syncToGameAttrs();

            this.setState({
                selectedCardIndex: -1,
                selectedHandCardIndex: -1,
                selectionSource: null
            });

            this.props.onLog(`${playerLabel}'s deck reshuffled.`);
        });
    }

    shuffleDeckOnly = () => {
        const { deck, discardPile, currentCards, handCards, updateState, label } = this.getCurrentPlayerProps();

        if (!window.confirm(`Are you sure you want to shuffle ${label}'s deck?`)) {
            return;
        }

        // Only shuffle the current deck
        const shuffledCards = shuffleDeck([...deck]);

        const result = updateState(shuffledCards, discardPile, currentCards, handCards);
        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label}'s deck shuffled.`);
        });
    }

    selectCard = (index) => {
        // If clicking the same card that's already selected, deselect it
        if (this.state.selectedCardIndex === index && this.state.selectionSource === 'current') {
            this.setState({
                selectedCardIndex: -1,
                selectedHandCardIndex: -1,
                selectionSource: null
            });
        } else {
            this.setState({
                selectedCardIndex: index,
                selectedHandCardIndex: -1,
                selectionSource: 'current'
            });
        }
    }

    selectHandCard = (index) => {
        // If clicking the same card that's already selected, deselect it
        if (this.state.selectedHandCardIndex === index && this.state.selectionSource === 'hand') {
            this.setState({
                selectedCardIndex: -1,
                selectedHandCardIndex: -1,
                selectionSource: null
            });
        } else {
            this.setState({
                selectedCardIndex: -1,
                selectedHandCardIndex: index,
                selectionSource: 'hand'
            });
        }
    }

    undoLastFlip = () => {
        const { currentCards, deck, updateState, label } = this.getCurrentPlayerProps();

        if (currentCards.length === 0) {
            return;
        }

        // Take the last card from current cards
        const newCurrentCards = [...currentCards];
        const lastCard = newCurrentCards.pop();

        // Put it back on top of the deck
        const newDeck = [lastCard, ...deck];

        const result = updateState(newDeck, this.getCurrentPlayerProps().discardPile, newCurrentCards);
        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label} returned a card to the top of the deck`);
        });
    }

    sendDiscardToHand = (index) => {
        const { deck, discardPile, currentCards, handCards, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = discardPile[index];
        const newDiscardPile = discardPile.filter((_, i) => i !== index);
        const newHandCards = [...handCards, selectedCard];

        const result = updateState(deck, newDiscardPile, currentCards, newHandCards);
        this.setState(result.newState, () => {
            this.syncToGameAttrs(newHandCards);
            this.props.onLog(`${label} moved ${selectedCard.label} from discard to hand`);
            this.setState({ selectedDiscardIndex: -1 });
        });
    }

    sendDiscardToDeck = (index) => {
        const { deck, discardPile, currentCards, handCards, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = discardPile[index];
        const newDiscardPile = discardPile.filter((_, i) => i !== index);
        const newDeck = [selectedCard, ...deck]; // Put on top of deck

        const result = updateState(newDeck, newDiscardPile, currentCards, handCards);
        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label} moved ${selectedCard.label} from discard to top of deck`);
            this.setState({ selectedDiscardIndex: -1 });
        });
    }

    sendDiscardToExile = (index) => {
        const { deck, discardPile, currentCards, handCards, exilePile, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = discardPile[index];
        const newDiscardPile = discardPile.filter((_, i) => i !== index);
        const newExilePile = [...exilePile, selectedCard];

        const result = updateState(deck, newDiscardPile, currentCards, handCards, newExilePile);

        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label} exiled ${selectedCard.label} from discard pile`);
        });
    }

    toggleDiscardDialog = () => {
        this.setState(prevState => ({
            isDiscardDialogOpen: !prevState.isDiscardDialogOpen,
            selectedDiscardIndex: -1
        }));
    }

    toggleOpponentDiscardDialog = () => {
        this.setState(prevState => ({
            isOpponentDiscardDialogOpen: !prevState.isOpponentDiscardDialogOpen
        }));
    }

    toggleExileDialog = () => {
        this.setState(prevState => ({
            isExileDialogOpen: !prevState.isExileDialogOpen,
            selectedExileIndex: -1
        }));
    }

    sendExileToHand = (index) => {
        const { deck, discardPile, currentCards, handCards, exilePile, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = exilePile[index];
        const newExilePile = exilePile.filter((_, i) => i !== index);
        const newHandCards = [...handCards, selectedCard];

        const result = updateState(deck, discardPile, currentCards, newHandCards, newExilePile);
        this.setState(result.newState, () => {
            this.syncToGameAttrs(newHandCards);
            this.props.onLog(`${label} moved ${selectedCard.label} from exile to hand`);
        });
    };

    sendExileToDeck = (index) => {
        const { deck, discardPile, currentCards, handCards, exilePile, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = exilePile[index];
        const newExilePile = exilePile.filter((_, i) => i !== index);
        const newDeck = [selectedCard, ...deck]; // Put on top of deck

        const result = updateState(newDeck, discardPile, currentCards, handCards, newExilePile);
        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label} moved ${selectedCard.label} from exile to top of deck`);
        });
    };

    sendExileToDiscard = (index) => {
        const { deck, discardPile, currentCards, handCards, exilePile, updateState, label } = this.getCurrentPlayerProps();
        const selectedCard = exilePile[index];
        const newExilePile = exilePile.filter((_, i) => i !== index);
        const newDiscardPile = [...discardPile, selectedCard];

        const result = updateState(deck, newDiscardPile, currentCards, handCards, newExilePile);
        this.setState(result.newState, () => {
            this.syncToGameAttrs();
            this.props.onLog(`${label} moved ${selectedCard.label} from exile to discard`);
        });
    };

    render() {
        const { onClose, open, game } = this.props;
        const {
            currentPlayer,
            playerSelectionLocked,
            selectedCardIndex,
            selectedHandCardIndex,
            selectionSource,
            cardsToFlip,
            cardsToDraw,
            isRevealMode,
            cardsToReveal,
            revealFromOpponent,
            isExileDialogOpen
        } = this.state;

        const currentPlayerProps = this.getCurrentPlayerProps();
        const opponentProps = this.getOpponentPlayerProps();

        const getOpponentCards = () => {
            const { currentCards, discardPile } = opponentProps;

            if (currentCards && currentCards.length > 0) {
                return {
                    cards: currentCards,
                    source: 'current',
                    exists: true
                };
            }

            if (discardPile && discardPile.length > 0) {
                return {
                    cards: [discardPile[discardPile.length - 1]],
                    source: 'discard',
                    exists: true
                };
            }

            return {
                cards: [],
                source: null,
                exists: false
            };
        };

        if (!open) return null;

        const player1Label = game?.attrs.player1Label || 'Player 1';
        const player2Label = game?.attrs.player2Label || 'Player 2';

        const revealedCards = getRevealedCards(
            this.state,
            revealFromOpponent,
            this.props.game
        );

        return (
            <>
                <Dialog
                    open={open}
                    onClose={onClose}
                    maxWidth="lg"
                    fullWidth
                >
                    <DialogTitle style={{ height: 60, fontSize: '1.4rem', paddingBottom: 0, paddingTop: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '1rem' }}>Card Flip</span>
                                {playerSelectionLocked && (
                                    <>
                                        <Typography
                                            variant="caption"
                                            style={{
                                                marginLeft: 8,
                                                color: '#d32f2f',
                                                fontStyle: 'italic'
                                            }}
                                        >
                                            {isRevealMode 
                                                ? 'Card Reveal Mode' 
                                                : `(Playing as ${currentPlayer === 1 ? player1Label : player2Label})`
                                            }
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={isRevealMode ? "secondary" : "primary"}
                                            size="small"
                                            onClick={() => this.toggleRevealMode()}
                                            style={{ marginLeft: 16 }}
                                        >
                                            {isRevealMode ? 'Back to Card Flip' : 'Reveal Cards'}
                                        </Button>
                                    </>
                                )}
                            </div>
                            {playerSelectionLocked && !isRevealMode && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ marginRight: 12, fontSize: '0.9rem' }}>Cards to flip:</Typography>
                                    <ButtonGroup size="small" color="primary">
                                        {[1, 2, 3, 4].map(num => (
                                            <Button
                                                key={num}
                                                variant={cardsToFlip === num ? 'contained' : 'outlined'}
                                                onClick={() => this.setCardsToFlip(num)}
                                                style={{ minWidth: '32px', padding: '2px 8px' }}
                                                disabled={!playerSelectionLocked}
                                            >
                                                {num}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            )}
                        </div>
                    </DialogTitle>
                    <Divider />

                    {/* Player Selection Dialog */}
                    {!playerSelectionLocked && (
                        <PlayerSelectionDialog
                            currentPlayer={currentPlayer}
                            changePlayer={this.changePlayer}
                            confirmPlayerSelection={this.confirmPlayerSelection}
                            player1Label={player1Label}
                            player2Label={player2Label}
                        />
                    )}

                    {/* Card Reveal Mode */}
                    {playerSelectionLocked && isRevealMode && (
                        <DialogContent style={{ padding: '24px' }}>
                            <RevealMode
                                cardsToReveal={cardsToReveal}
                                setCardsToReveal={this.setCardsToReveal}
                                revealFromOpponent={revealFromOpponent}
                                toggleRevealTarget={this.toggleRevealTarget}
                                revealCards={this.revealCards}
                                toggleRevealMode={this.toggleRevealMode}
                                revealedCards={revealedCards}
                                currentPlayerProps={currentPlayerProps}
                                opponentProps={opponentProps}
                                handleRevealedCardAction={this.handleRevealedCardAction}
                                sharedRevealDialogOpen={this.state.sharedRevealDialogOpen}
                                sharedRevealedCards={this.state.sharedRevealedCards}
                                handleCloseSharedRevealDialog={this.handleCloseSharedRevealDialog}
                            />
                        </DialogContent>
                    )}

                    {/* Card Flip Mode */}
                    {playerSelectionLocked && !isRevealMode && (
                        <CardFlipMode
                            label={currentPlayerProps.label}
                            deck={currentPlayerProps.deck}
                            discardPile={currentPlayerProps.discardPile}
                            currentCards={currentPlayerProps.currentCards}
                            handCards={currentPlayerProps.handCards}
                            exilePile={currentPlayerProps.exilePile}
                            opponentProps={opponentProps}
                            cardsToFlip={cardsToFlip}
                            cardsToDraw={cardsToDraw}
                            selectionSource={selectionSource}
                            selectedCardIndex={selectedCardIndex}
                            selectedHandCardIndex={selectedHandCardIndex}
                            onFlipCards={this.flipCards}
                            onUndoLastFlip={this.undoLastFlip}
                            onSelectCard={this.selectCard}
                            onSelectHandCard={this.selectHandCard}
                            onUseSelectedCard={this.useSelectedCard}
                            onSetCardsToDraw={this.setCardsToDraw}
                            onDrawCardsToHand={this.drawCardsToHand}
                            onReshuffleDeck={this.reshuffleDeck}
                            onShuffleDeckOnly={this.shuffleDeckOnly}
                            onSendDiscardToHand={this.sendDiscardToHand}
                            onSendDiscardToDeck={this.sendDiscardToDeck}
                            onSendDiscardToExile={this.sendDiscardToExile}
                            onToggleExileDialog={this.toggleExileDialog}
                            onToggleOpponentDiscardDialog={this.toggleOpponentDiscardDialog}
                            getOpponentCards={getOpponentCards}
                        />
                    )}

                    <DialogActions style={{ padding: '8px 16px' }}>
                        <Button onClick={onClose} variant="contained" color="primary">Close</Button>
                    </DialogActions>
                </Dialog>

                <ExileDialog
                    open={isExileDialogOpen}
                    onClose={this.toggleExileDialog}
                    exilePile={currentPlayerProps.exilePile}
                    onSendToHand={this.sendExileToHand}
                    onSendToDeck={this.sendExileToDeck}
                    onSendToDiscard={this.sendExileToDiscard}
                />

                <OpponentDiscardDialog
                    open={this.state.isOpponentDiscardDialogOpen}
                    onClose={this.toggleOpponentDiscardDialog}
                    discardPile={opponentProps.discardPile}
                    playerLabel={opponentProps.label}
                />

                {/* Shared Reveal Dialog - Only shown to the opponent player */}
                <Dialog
                    open={this.state.sharedRevealDialogOpen && 
                          this.state.sharedRevealPlayerNum !== parseInt(localStorage.getItem(`currentPlayer_${this.props.game.id}`), 10)}
                    onClose={this.handleCloseSharedRevealDialog}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        {this.state.sharedRevealPlayerNum === 1 ? player1Label : player2Label} Revealed Cards
                    </DialogTitle>
                    <DialogContent>
                        {this.state.sharedRevealedCards && this.state.sharedRevealedCards.length > 0 ? (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
                                {this.state.sharedRevealedCards.map((card, index) => (
                                    <div key={index} style={{ textAlign: 'center' }}>
                                        <img
                                            src={card.imageUrl}
                                            alt={card.label}
                                            style={{ 
                                                width: '120px', 
                                                height: '180px', 
                                                objectFit: 'contain',
                                                margin: '8px',
                                                borderRadius: '8px' 
                                            }}
                                        />
                                        <Typography variant="caption" display="block">
                                            {card.label}
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Typography variant="body1" align="center">
                                No cards revealed yet
                            </Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSharedRevealDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}