// filepath: c:\Users\peter\source\repos\wartable-1\src\map\shapes\Arc.js
import { fabric } from 'fabric-with-gestures';
import BaseShape from './BaseShape';

export default class Arc extends BaseShape {
  createFabricObject() {
    const circle = new fabric.Circle({
      strokeWidth: 1,
      fill: '',
      originX: 'center',
      originY: 'center',
      hasControls: false,
      layer: this.layer(),
    });
    
    // Define custom render method when creating the object
    circle._render = function(ctx) {
      if (this.startAngle === undefined || this.endAngle === undefined) {
        // Fall back to normal circle rendering if angles aren't defined
        fabric.Circle.prototype._render.call(this, ctx);
        return;
      }
      
      ctx.beginPath();
      ctx.arc(0, 0, this.radius, this.startAngle, this.endAngle);
      
      if (this.stroke) {
        ctx.stroke();
      }
      
      if (this.fill !== 'transparent' && this.fill !== '') {
        ctx.fillStyle = this.fill;
        ctx.fill();
      }
    };
    
    return circle;
  }
  
  shapeAttrs(attrs, pixelsPerInch) {
    let radius = (pixelsPerInch * attrs.width) / 2;
    if (radius < 2) {
      radius = 2;
    }
    
    // Convert angles for fabric.js (which uses radians)
    const startAngle = (attrs.startAngle || 0) * (Math.PI / 180);
    const endAngle = (attrs.endAngle || 360) * (Math.PI / 180);
    
    return {
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      radius: radius - 1,
      stroke: attrs.stroke,
      fill: attrs.fill,
      startAngle: startAngle,
      endAngle: endAngle,
      angle: attrs.rotation || 0
    };
  }
  
  updateFabricObject(fabricObject, shapeAttrs) {
    // We don't need to override _render here anymore as we do it during creation
    super.updateFabricObject(fabricObject, shapeAttrs);
  }
}