import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { onValue, ref, remove } from '@firebase/database';

import { db } from '../../../initFirebase';
import Dialog from '../../../WTDialog';
import Scenarios from '../Scenarios';
import { widthFor } from '../../../map/Token';

const MAPS_WITH_ZONES = [  
  'swamp',
];

export default class ScenarioDialog extends Component {
  resetScenario = () => {
    const { connection } = this.props;
    return new Promise((resolve) => {
      onValue(
        ref(db, connection.rootPath()),
        (data) => {
          const refsToRemove = [];
          data.child('shapes').forEach((shape) => {
            const val = shape.val();
            if (val.scenario) {
              refsToRemove.push(shape.ref);
            }
          });
          data.child('tokens').forEach((token) => {
            const val = token.val();
            if (val.scenario) {
              refsToRemove.push(token.ref);
            }
          });
          data.child('widgets').forEach((widget) => {
            const val = widget.val();
            if (val.widgetType === 'Counter') {
              refsToRemove.push(widget.ref);
            }
          });
          Promise.all(refsToRemove.map((ref) => remove(ref))).then(resolve);
        },
        { onlyOnce: true }
      );
    });
  };
  setScenario = (scenario) => {
    this.props.log(`Selected scenario: ${scenario.name}`);
    this.createScenario(scenario);
    this.props.onClose();
  };
  randomScenario = (scenarios) => {
    const roll = Math.floor(Math.random() * scenarios.length);
    const scenario = scenarios[roll];
    this.props.log(`Rolled ${roll + 1} for scenario: ${scenario.name}`);
    this.createScenario(scenario);
    this.props.onClose();
  };
  createScenario(scenario) {
    this.resetScenario().then(() => this.createScenarioElements(scenario));
  }
  createScenarioElements = (scenario) => {
    const elements = scenario.elements;
    const { connection, game } = this.props;
    let roundZoneIndex = 1;
    elements.forEach((element) => {
      const attrs = {
        x: element.x,
        y: element.y,
        facing: element.facing || 0,
        deletable: false,
        scenario: true,
      };
      if (element.type === 'Circle') {
        attrs.type = 'Circle';
        attrs.width = element.radius || 12;
        MAPS_WITH_ZONES.forEach((map) => {
          if (game.attrs.backgroundImageUrl === `/backgrounds/${map}.jpg`) {
            attrs.type = 'Image';
            attrs.imageUrl = `/terrain/${map}/zone-round${roundZoneIndex}.png`;
            roundZoneIndex += 1;
            attrs.height = attrs.width;
          }
        });
        attrs.fill = element.fill || '#9e9e9e';
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#000000';
        connection.add('shape', attrs);
      }
      if (element.type === 'Arc') {
        attrs.type = 'Arc';
        attrs.width = element.radius || 12;
        attrs.startAngle = element.startAngle || 0;
        attrs.endAngle = element.endAngle || 360;
        attrs.fill = element.fill || 'transparent';
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#ffffff';
        connection.add('shape', attrs);
      }
      if (element.type === 'strategyMarker') {
        attrs.width = widthFor(element.size || '30mm');
        attrs.color = element.color || '#9c27b0';
        attrs.label = element.label || 'Strategy';
        attrs.immobile =
          element.immobile === undefined ? true : element.immobile;
        connection.addToken(attrs);
      }
      if (element.type === 'strategyBallot') {
        attrs.width = widthFor(element.size || '30mm');
        attrs.color = element.color || '#000000';
        if (element.imageUrl) attrs.imageUrl = element.imageUrl;
        attrs.resource1 = 0;
        attrs.resource1Label = " "
        attrs.resource2 = 0;
        attrs.resource2Label = "Player 1 Votes"
        attrs.resource3 = 0;
        attrs.resource3Label = "Player 2 Votes"
        attrs.label = element.label || 'Ballot';
        attrs.immobile =
          element.immobile === undefined ? true : element.immobile;
        connection.addToken(attrs);
      }      
      if (element.type === 'Line') {
        attrs.type = 'Line';
        attrs.width = element.width;
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#9e9e9e';
        // Add rotation support for lines
        if (element.rotation !== undefined) {
          attrs.rotation = element.rotation;
        }
        connection.add('shape', attrs);
      }
      if (element.type === 'Image') {
        attrs.type = 'Image';
        attrs.width = element.width;
        attrs.height = element.height;
        attrs.imageUrl = element.imageUrl;
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#9e9e9e';
        connection.add('shape', attrs);
      }
    });    

    [3, 33].forEach((counter) =>
      connection.add('widget', {
        widgetType: 'Counter',
        x: -7,
        y: counter,
        label: 'VPs',
        value: 0,
        fill: '#f44336',
        facing: 0,
        deletable: true,
      })
    );    
    connection.add('widget', {
      widgetType: 'Counter',
      x: -3,
      y: 18,
      label: 'Turn',
      value: 1,
      fill: '#9e9e9e',
      facing: 0,
      deletable: true,
    });
  };
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog maxWidth="md" open onClose={onClose}>
        <DialogTitle>Select a scenario</DialogTitle>
        <DialogContent>
          <div>
            <Button
              style={{ marginBottom: 16, marginRight: 16 }}
              color="primary"
              variant="contained"
              onClick={() => this.resetScenario().then(onClose)}
            >
              Clear
            </Button>
          </div>
          {Scenarios.map((section, i) => (
            <Fragment key={i}>
              <Typography variant="h4">{section.section}</Typography>
              {section.description && (
                <Typography variant="body2" style={{ marginBottom: 16 }}>
                  {section.description}
                </Typography>
              )}
              <Button
                style={{ marginBottom: 16, marginRight: 16 }}
                color="primary"
                variant="contained"
                onClick={() => this.randomScenario(section.scenarios)}
              >
                Random
              </Button>
              <div
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '8px',
                  marginBottom: '16px'
                }}
              >
                {section.scenarios.map((scenario, j) => (
                  <Button
                    key={j}
                    variant="outlined"
                    color="primary"
                    onClick={() => this.setScenario(scenario)}
                    style={{ margin: '4px' }}
                  >
                    {scenario.name}
                  </Button>
                ))}
              </div>
            </Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
