import { fabric } from 'fabric-with-gestures';
import BaseShape from './BaseShape';

export default class Line extends BaseShape {
  createFabricObject() {
    return new fabric.Line([], {
      strokeWidth: 1,
      hasControls: false,
      layer: this.layer(),
    });
  }
  shapeAttrs(attrs, pixelsPerInch) {
    // Use rotation if specified, otherwise fall back to facing
    let angle = attrs.rotation !== undefined ? attrs.rotation : attrs.facing;
    let radians = (Math.PI * angle) / 180;
    let distanceFromCenter = attrs.width / 2;
    return {
      stroke: attrs.stroke,
      x1: pixelsPerInch * (attrs.x - Math.cos(radians) * distanceFromCenter),
      y1: pixelsPerInch * (attrs.y - Math.sin(radians) * distanceFromCenter),
      x2: pixelsPerInch * (attrs.x + Math.cos(radians) * distanceFromCenter),
      y2: pixelsPerInch * (attrs.y + Math.sin(radians) * distanceFromCenter),
    };
  }
  maxLabelSize() {
    return 1;
  }
}
