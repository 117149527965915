import React from 'react';
import Dialog from '../../../../../WTDialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const OpponentDiscardDialog = ({ open, onClose, discardPile, playerLabel }) => {
  // Reverse the discard pile array to display newest cards first (on the left)
  const reversedDiscardPile = [...discardPile].reverse();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {playerLabel}'s Discard Pile ({discardPile.length} cards)
      </DialogTitle>
      <DialogContent>
        {discardPile.length === 0 ? (
          <Typography variant="body1" align="center">
            No cards in discard pile
          </Typography>
        ) : (
          <Box 
            sx={{ 
              position: 'relative',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'hidden',
              height: 'auto',
              padding: '8px',
              display: 'flex'
            }}
          >
            <div style={{ 
              display: 'flex', 
              position: 'relative', 
              minWidth: 'min-content',
              height: '250px' 
            }}>
              {reversedDiscardPile.map((card, index) => {
                // Calculate offset for overlapping cards
                const leftOffset = index * 40; // 40px offset for each card
                
                return (
                  <div 
                    key={`${card.label}-${index}`}
                    style={{
                      position: 'absolute',
                      left: leftOffset,
                      // Higher z-index for earlier cards (more recent)
                      zIndex: 1000 - index
                    }}
                  >
                    <img
                      src={card.imageUrl}
                      alt={card.label}
                      title={card.label}
                      style={{
                        height: '250px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.2)'
                      }}
                    />
                  </div>
                );
              })}
              {/* This empty div ensures the container is wide enough to show all cards */}
              <div style={{ width: (discardPile.length * 40) + 150 }} />
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpponentDiscardDialog;