import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Dialog from '../../../../../WTDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

const RevealMode = ({
    cardsToReveal,
    setCardsToReveal,
    revealFromOpponent,
    toggleRevealTarget,
    revealCards,
    toggleRevealMode,
    revealedCards,
    currentPlayerProps,
    opponentProps,
    handleRevealedCardAction,
    // Shared reveal dialog props from parent
    sharedRevealDialogOpen,
    sharedRevealedCards,
    handleCloseSharedRevealDialog
}) => {
    const revealOptions = Array.from({ length: 20 }, (_, i) => i + 1);
    
    // Function to handle private "Look at" action
    const handleLookAt = () => {
        revealCards(false); // False means private reveal (only for current player)
    };

    // Function to handle public "Reveal" action
    const handleReveal = () => {
        revealCards(true); // True means shared reveal (visible to opponent)
    };
    
    // Check if there are cards that need decisions
    const hasRevealedCards = revealedCards.length > 0;
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
            {hasRevealedCards && (
                <Alert severity="warning" style={{ width: '100%' }}>
                    You must make a decision for all revealed cards (discard, exile, hand, or top deck) before revealing more cards.
                </Alert>
            )}
            
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                padding: '24px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.02)'
            }}>
                <Typography variant="h6" align="center">
                    Card Viewing Options
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <select
                        value={cardsToReveal}
                        onChange={(e) => setCardsToReveal(e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            padding: '8px',
                            fontSize: '16px',
                            minWidth: '100px',
                            borderRadius: '4px',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            opacity: hasRevealedCards ? 0.6 : 1,
                        }}
                        disabled={hasRevealedCards}
                    >
                        {revealOptions.map(num => (
                            <option key={num} value={num}>
                                {num} {num === 1 ? 'card' : 'cards'}
                            </option>
                        ))}
                    </select>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={toggleRevealTarget}
                        disabled={hasRevealedCards}
                        style={{ opacity: hasRevealedCards ? 0.6 : 1 }}
                    >
                        View from {revealFromOpponent ? opponentProps.label : currentPlayerProps.label}
                    </Button>
                </div>
                <div style={{ display: 'flex', gap: '16px', marginTop: '8px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLookAt}
                        disabled={hasRevealedCards}
                        style={{ opacity: hasRevealedCards ? 0.6 : 1 }}
                    >
                        {hasRevealedCards ? 'Handle current cards first' : 'Look at'}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleReveal}
                        disabled={hasRevealedCards}
                        style={{ opacity: hasRevealedCards ? 0.6 : 1 }}
                    >
                        {hasRevealedCards ? 'Handle current cards first' : 'Reveal'}
                    </Button>
                </div>
            </div>

            {/* Revealed Cards Display (for the current player only) */}
            {hasRevealedCards && (
                <div style={{ width: '100%' }}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Revealed Cards ({revealedCards.length} remaining)
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
                        {revealedCards.map((card, index) => (
                            <div key={index} style={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                gap: '8px',
                                border: '2px solid #f50057',
                                borderRadius: '8px',
                                padding: '8px',
                                backgroundColor: 'rgba(245, 0, 87, 0.05)'
                            }}>
                                <img
                                    src={card.imageUrl}
                                    alt={card.label}
                                    style={{ width: '120px', height: '180px', objectFit: 'contain' }}
                                />
                                <Typography variant="caption" align="center" style={{ fontWeight: 'bold' }}>
                                    {card.label}
                                </Typography>
                                <ButtonGroup size="small">
                                    <Button onClick={() => handleRevealedCardAction(index, 'discard')}>
                                        Discard
                                    </Button>
                                    <Button onClick={() => handleRevealedCardAction(index, 'exile')}>
                                        Exile
                                    </Button>
                                    <Button onClick={() => handleRevealedCardAction(index, 'hand')}>
                                        Hand
                                    </Button>
                                    <Button onClick={() => handleRevealedCardAction(index, 'deck')}>
                                        Top Deck
                                    </Button>
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            
            <div style={{ marginTop: '16px' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={toggleRevealMode}
                    disabled={hasRevealedCards}
                    style={{ 
                        opacity: hasRevealedCards ? 0.6 : 1,
                    }}
                >
                    {hasRevealedCards ? 
                        `Handle all revealed cards to exit (${revealedCards.length} remaining)` : 
                        'Return to Card Flip Mode'}
                </Button>
            </div>
        </div>
    );
};

export default RevealMode;