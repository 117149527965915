import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import PlayerSelector from './PlayerSelector';

const PlayerSelectionDialog = ({
    currentPlayer,
    changePlayer,
    confirmPlayerSelection,
    player1Label,
    player2Label
}) => {
    return (
        <DialogContent style={{ padding: '40px 20px', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
                Please select which player you are and click "Confirm"
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
                <PlayerSelector
                    currentPlayer={currentPlayer}
                    onPlayerChange={changePlayer}
                    player1Label={player1Label}
                    player2Label={player2Label}
                    isLocked={false}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={confirmPlayerSelection}
                >
                    Confirm as {currentPlayer === 1 ? player1Label : player2Label}
                </Button>
            </div>
        </DialogContent>
    );
};

export default PlayerSelectionDialog;