// This file imports all card images and provides them in a structured way

// Import card back
import cardBack from '../assets/cards/card-back.png';

// Create importable paths for all cards
const importCard = (suit, value) => {
  try {
    // Using require for dynamic imports
    return require(`../assets/cards/${suit.toLowerCase()}/${value}.png`);
  } catch (e) {
    console.error(`Could not load card image for ${value} of ${suit}`, e);
    return null; // Return null if image doesn't exist
  }
};

// Import jokers
const importJoker = (color) => {
  try {
    return require(`../assets/cards/jokers/${color}.png`);
  } catch (e) {
    console.error(`Could not load ${color} joker image`, e);
    return null;
  }
};

// Export the card back image
export const cardBackImage = cardBack;

// Function to get a card image
export const getCardImage = (suit, value) => {
  if (suit === 'red' || suit === 'black') {
    return importJoker(suit);
  }
  return importCard(suit, value);
};

// Function to get an image URL that works with both development and production builds
export const getImageUrlForPublic = (imagePath) => {
  // This returns the full URL path that browsers can access
  return process.env.PUBLIC_URL + imagePath;
};
