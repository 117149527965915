export default [
  {
    name: "Marker 30mm",
    type: "Marker",
    faction: "",
    avatar: "",
    size: "30mm",    
  },
  {
    name: "Marker 50mm",
    type: "Marker",
    faction: "",
    avatar: "",
    size: "50mm",    
  },  
  {
    "name": "Sandeep Desai",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Sandeep-Desai.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Sandeep Desai, Font Of Magic",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Sandeep-Desai-Font-of-Magic.jpg",
    "size": 40,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Kudra",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Kudra.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Shastar Vidiya Guard",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Shastar-Vidiya-Guard.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Oxfordian Mage",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Oxfordian-Mage.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Kandara",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Kandara.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Banasuva",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Banasuva.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Joss",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Joss.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Medical Automaton",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Medical-Automaton.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Mobile Toolkit",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mobile-Toolkit.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Marcus",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Marcus.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Marcus, Alpha",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Marcus-Alpha.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Myranda",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Myranda.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Ferdinand Vogel",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Ferdinand-Vogel.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Order Initiate",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Order-Initiate.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Cojo",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Cojo.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "The Beast Within",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/The-Beast-Within.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Sabertooth Cerberus",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Sabertooth-Cerberus.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Slate Ridge Mauler",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Slate-Ridge-Mauler.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Moleman",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Moleman.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "The Scorpius",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/The-Scorpius.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Jackalope",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Jackalope.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Paul Crockett",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Paul-Crockett.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Envy",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Envy.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Rasputina",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Rasputina.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Rasputina, Abominable",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Rasputina-Abominable.jpg",
    "size": 40,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Snow Storm",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Snow-Storm.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "December Acolyte",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/December-Acolyte.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Silent One",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Silent-One.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Wendigo",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Wendigo.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Blessed of December",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Blessed-of-December.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Hoarcat",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Hoarcat.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Ice Golem",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Ice-Golem.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Ice Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Ice-Gamin.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Ice Dancer",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Ice-Dancer.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Arcane Effigy",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Arcane-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Wind Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Wind-Gamin.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Poison Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Poison-Gamin.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Essence of Power",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Essence-of-Power.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Arcane Emissary",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Arcane-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Kaeris",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Kaeris.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Kaeris, Reborn",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Kaeris-Reborn.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Elijah Borgmann",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Elijah-Borgmann.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "The Firestarter",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/The-Firestarter.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Firebranded",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Firebranded.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Eternal Flame",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Eternal-Flame.jpg",
    "size": 40,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Fire Golem",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Fire-Golem.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Fire Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Fire-Gamin.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Neil Henry",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Neil-Henry.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Willie",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Willie.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Metal Golem",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Metal-Golem.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Metal Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Metal-Gamin.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Mechanical Rider",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mechanical-Rider.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Toni Ironsides",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Toni-Ironsides.jpg",
    "size": 30,
    "health": 14,
    "maxMeleeRange": 1
  },
  {
    "name": "Toni Ironsides, Union President",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Toni-Ironsides-Union-President.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "The Captain",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/The-Captain.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Amina Naidu",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Amina-Naidu.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Fitzsimmons",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Fitzsimmons.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Gunsmith",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Gunsmith.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Union Miner",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Union-Miner.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Mouse",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mouse.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Howard Langston",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Howard-Langston.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Union Steamfitter",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Union-Steamfitter.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Colette Du Bois",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Colette-Du-Bois.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Colette Du Bois, Smuggler",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Colette-Du-Bois-Smuggler.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Cassandra Felton",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Cassandra-Felton.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Angelica Durand",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Angelica-Durand.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Showgirl",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Showgirl.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Mannequin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mannequin.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Mechanical Dove",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mechanical-Dove.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Carlos Vasquez",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Carlos-Vasquez.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Coryphee Duet",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Coryphee-Duet.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Coryphee",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Coryphee.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Soulstone Miner",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Soulstone-Miner.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Steam Arachnid Swarm",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Steam-Arachnid-Swarm.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Mecharachnid",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Mecharachnid.jpg",
    "size": 50,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Saboteur",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Saboteur.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Steam Arachnid",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Steam-Arachnid.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Bayou Smuggler",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bayou-Smuggler.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Som'er Teeth Jones",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Somer-Teeth-Jones.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 0
  },
  {
    "name": "Som'er Teeth Jones, Loot Monger",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Somer-Teeth-Jones-Loot-Monger.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Lenny Jones",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Lenny-Jones.jpg",
    "size": 40,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Georgy and Olaf",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Georgy-and-Olaf.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Good Ol' Boy",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Good-Ol'-Boy.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Spit Hog",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Spit-Hog.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Gremlin Crier",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Gremlin-Crier.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Banjonista",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Banjonista.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Old Cranky",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Old-Cranky.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Bayou Gremlin",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bayou-Gremlin.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Skeeter",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Skeeter.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Gluttony",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Gluttony.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Lucky Effigy",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Lucky-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Lucky Emissary",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Lucky-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Mechanized Porkchop",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Mechanized-Porkchop.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Captain Zipp",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Captain-Zipp.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Captain Zipp, Dread Pirate",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Captain-Zipp-Dread-Pirate.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Mancha Roja",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Mancha-Roja.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Iron Skeeter",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Iron-Skeeter.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Wrastler",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Wrastler.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Earl Burns",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Earl-Burns.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "The First Mate",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-First-Mate.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Burt Jebsen",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Burt-Jebsen.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Flying Piglet",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Flying-Piglet.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Ophelia LaCroix",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Ophelia-LaCroix.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Ophelia LaCroix, Overloaded",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Ophelia-LaCroix-Overloaded.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Francois LaCroix",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Francois-LaCroix.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Raphael LaCroix",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Raphael-LaCroix.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Pere Ravage",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Pere-Ravage.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Rami LaCroix",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Rami-LaCroix.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "LaCroix Raider",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/LaCroix-Raider.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Young LaCroix",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Young-LaCroix.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Merris LaCroix",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Merris-LaCroix.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Sammy LaCroix",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Sammy-LaCroix.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Old Major",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Old-Major.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "War Pig",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/War-Pig.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Gracie",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Gracie.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "The Sow",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Sow.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Wild Boar",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Wild-Boar.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Squealer",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Squealer.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Piglet",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Piglet.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Ulix Turner",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Ulix-Turner.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Ulix Turner, Porkbelly Protector",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Ulix-Turner-Porkbelly-Protector.jpg",
    "size": 50,
    "health": 14,
    "maxMeleeRange": 0
  },
  {
    "name": "Hog Whisperer",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Hog-Whisperer.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Slop Hauler",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Slop-Hauler.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Penelope",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Penelope.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "McTavish",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/McTavish.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Spawn Mother",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Spawn-Mother.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Silurid",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Silurid.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Bayou Gator",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bayou-Gator.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Gupps",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Gupps.jpg",
    "size": 40,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Gautraeux Bokor",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Gautraeux-Bokor.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "The Brewmaster",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Brewmaster.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "The Brewmaster, Moonshiner",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Brewmaster-Moonshiner.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Whiskey Golem",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Whiskey-Golem.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Fingers Leong",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Fingers-Leong.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Popcorn Turner",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Popcorn-Turner.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Cooper Jones",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Cooper-Jones.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Moon Shinobi",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Moon-Shinobi.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Whiskey Gamin",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Whiskey-Gamin.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Apprentice Wesley",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Apprentice-Wesley.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 2
  },
  {
    "name": "Akaname",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Akaname.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Mah Tucket",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Mah-Tucket.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Mah Tucket, Mecha Meemaw",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Mah-Tucket-Mecha-Meemaw.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 2
  },
  {
    "name": "Big Brain Brin",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Big-Brain-Brin.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Trixiebelle",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Trixiebelle.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Bushwhacker",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bushwhacker.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Survivor",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Survivor.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "The Little Lass",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Little-Lass.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Rooster Rider",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Rooster-Rider.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Test Subject",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Test-Subject.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Sparks LeBlanc",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Sparks-LeBlanc.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Wong",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Wong.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Wong, The Enchanter",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Wong-The-Enchanter.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Alphonse LeBlanc",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Alphonse-LeBlanc.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Taxidermist",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Taxidermist.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Pigapult",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Pigapult.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Swine-Cursed",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Swine-Cursed.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Lightning Bug",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Lightning-Bug.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Olivia Bernard",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Olivia-Bernard.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Stuffed Piglet",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Stuffed-Piglet.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Collodi",
    "type": "Master",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Collodi.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Marionette",
    "type": "Minion",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Marionette.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Lilith",
    "type": "Master",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Lilith.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Cherub",
    "type": "Enforcer",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Cherub.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Nicodem",
    "type": "Master",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Nicodem.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Vulture",
    "type": "Enforcer",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Vulture.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Ryle Hoffman",
    "type": "Henchman",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Ryle-Hoffman.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Ramos",
    "type": "Master",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Ramos.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Electrical Creation",
    "type": "Minion",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Electrical-Creation.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Brass Arachnid",
    "type": "Enforcer",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Brass-Arachnid.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Dr. Grimwell",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Dr-Grimwell.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Nurse Heartsbane",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Nurse-Heartsbane.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Orderly",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Orderly.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Charles Hoffman",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Charles-Hoffman.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Charles Hoffman, Inventor",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Charles-Hoffman-Inventor.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 2
  },
  {
    "name": "Peacekeeper",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Peacekeeper.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Guardian",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guardian.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Melissa K.O.R.E.",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Melissa-K.O.R.E.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Hunter",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Hunter.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Riotbreaker",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Riotbreaker.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Watcher",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Watcher.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Mechanical Attendant",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Mechanical-Attendant.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Warden",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Warden.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Greed",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Greed.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Brutal Effigy",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Brutal-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Lucius Mattheson",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Lucius-Mattheson.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Lucius Mattheson, Dishonorable",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Lucius-Mattheson-Dishonorable.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Agent 46",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Agent-46.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Alan Reid",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Alan-Reid.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Investigator",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Investigator.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Guild Lawyer",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guild-Lawyer.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "The Scribe",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Scribe.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Brutal Emissary",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Brutal-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Perdita Ortega",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Perdita-Ortega.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Perdita Ortega, Neverborn Hunter",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Perdita-Ortega-Neverborn-Hunter.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 1
  },
  {
    "name": "Francisco Ortega",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Francisco-Ortega.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Santiago Ortega",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Santiago-Ortega.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Nino Ortega",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Nino-Ortega.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Papa Loco",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Papa-Loco.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Monster Hunter",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Monster-Hunter.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Abuela Ortega",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Abuela-Ortega.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Pistolero De Latigo",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Pistolero-De-Latigo.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Enslaved Nephilim",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Enslaved-Nephilim.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Cornelius Basse",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Cornelius-Basse.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Cornelius Basse, Badlands Sheriff",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Cornelius-Basse-Badlands-Sheriff.jpg",
    "size": 50,
    "health": 13,
    "maxMeleeRange": 2
  },
  {
    "name": "Jonathan Reichart",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Jonathan-Reichart.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Austringer",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Austringer.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Pathfinder",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Pathfinder.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Frontiersman",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Frontiersman.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Clockwork Trap",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Clockwork-Trap.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 1
  },
  {
    "name": "Bernadette Basse",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Bernadette-Basse.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Trained Raptor",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Trained-Raptor.jpg",
    "size": 40,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Dashel Barker",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Dashel-Barker.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Dashel Barker, Butcher",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Dashel-Barker-Butcher.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 2
  },
  {
    "name": "Executioner",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Executioner.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Taggart Queeg",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Taggart-Queeg.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Mounted Guard",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Mounted-Guard.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Sergeant",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sergeant.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Rifleman",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Rifleman.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Guard Patrol",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guard-Patrol.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "The Dispatcher",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Dispatcher.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Guild Hound",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guild-Hound.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Pale Rider",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Pale-Rider.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Nellie Cochrane",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Nellie-Cochrane.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Nellie Cochrane, Voice Of Disorder",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Nellie-Cochrane-Voice-of-Disorder.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Phiona Gage",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Phiona-Gage.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Allison Dade",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Allison-Dade.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Undercover Reporter",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Undercover-Reporter.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Field Reporter",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Field-Reporter.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Newsie",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Newsie.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "The Printing Press",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Printing-Press.jpg",
    "size": 40,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "False Witness",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/False-Witness.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Lady Justice",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Lady-Justice.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Lady Justice, Death Touched",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Lady-Justice-Death-Touched.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "The Judge",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Judge.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "The Lone Marshal",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Lone-Marshal.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "The Jury",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/The-Jury.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Death Marshal Recruiter",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Death-Marshal-Recruiter.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Domador De Cadaveres",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Domador-De-Cadaveres.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Exorcist",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Exorcist.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Death Marshal",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Death-Marshal.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Scales of Justice",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Scales-of-Justice.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Sonnia Criid",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sonnia-Criid.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Sonnia Criid, Unmasked",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sonnia-Criid-Unmasked.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Samael Hopkins",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Samael-Hopkins.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Witchling Handler",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Witchling-Handler.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Thalarian Queller",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Thalarian-Queller.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Sanctioned Spellcaster",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sanctioned-Spellcaster.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Witchling Thrall",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Witchling-Thrall.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Witchling Stalker",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Witchling-Stalker.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Purifying Flame",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Purifying-Flame.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Guild Steward",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guild-Steward.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Razorspine Rattler",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Razorspine-Rattler.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Wrath",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Wrath.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Mysterious Effigy",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Mysterious-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Doppleganger",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Doppleganger.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Changeling",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Changeling.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Mysterious Emissary",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Mysterious-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Titania",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Titania.jpg",
    "size": 30,
    "health": 14,
    "maxMeleeRange": 1
  },
  {
    "name": "Titania, Autumn Queen",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Titania-Autumn-Queen.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Killjoy",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Killjoy.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Aeslin",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Aeslin.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Autumn Knight",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Autumn-Knight.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Gorar",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Gorar.jpg",
    "size": 40,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Rougarou",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Rougarou.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Bultungin",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Bultungin.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Iggy",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Iggy.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Angel Eyes",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Angel-Eyes.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Tuco Ortega",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Tuco-Ortega.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Maurice",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Maurice.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Bloodwretch",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Bloodwretch.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Hooded Rider",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Hooded-Rider.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Nekima",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Nekima.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Nekima, Broodmother",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Nekima-Broodmother.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Mature Nephilim",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Mature-Nephilim.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Hayreddin",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Hayreddin.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Lelu",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Lelu.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Lilitu",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Lilitu.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Young Nephilim",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Young-Nephilim.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Black Blood Shaman",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Black-Blood-Shaman.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Terror Tot",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Terror-Tot.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Corrupted Hound",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Corrupted-Hound.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Blood Hunter",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Blood-Hunter.jpg",
    "size": 40,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "The Dreamer",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Dreamer.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "The Dreamer, Insomniac",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Dreamer-Insomniac.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Teddy",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Teddy.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Coppelius",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Coppelius.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Lord Chompy Bits",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Lord-Chompy-Bits.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Widow Weaver",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Widow-Weaver.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Serena Bowman",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Serena-Bowman.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Bandersnatch",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Bandersnatch.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Insidious Madness",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Insidious-Madness.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Alp",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Alp.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Daydream",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Daydream.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Stitched Together",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Stitched-Together.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "The Carver",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Carver.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Vasilisa",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Vasilisa.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Wicked Doll",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Wicked-Doll.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Hinamatsu",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Hinamatsu.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Euripides",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Euripides.jpg",
    "size": 50,
    "health": 14,
    "maxMeleeRange": 1
  },
  {
    "name": "Euripides, Old One Eye",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Euripides-Old-One-Eye.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Thoon",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Thoon.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Geryon",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Geryon.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Cyclops",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Cyclops.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Gigant",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Gigant.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Primordial Magic",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Primordial-Magic.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Lyssa",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Lyssa.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Zoraida",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Zoraida.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Zoraida, Swamp Hag",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Zoraida-Swamp-Hag.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Bad Juju",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Bad-Juju.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Adze",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Adze.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Will o' the Wisp",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Will-o'-the-Wisp.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 2
  },
  {
    "name": "Grootslang",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Grootslang.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Waldgeist",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Waldgeist.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Voodoo Doll",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Voodoo-Doll.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Pandora",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Pandora.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Pandora, Tyrant Torn",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Pandora-Tyrant-Torn.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Candy",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Candy.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Baby Kade",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Baby-Kade.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Aversion",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Aversion.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Sorrow",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Sorrow.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Poltergeist",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Poltergeist.jpg",
    "size": 50,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Leveticus",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Leveticus.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Leveticus, Pariah Of Blood",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Leveticus-Pariah-of-Blood.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Desolation Engine",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Desolation-Engine.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Ashes and Dust",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Ashes-and-Dust.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Rusty Alyce",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Rusty-Alyce.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Marlena Webster",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Marlena-Webster.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Scavenger",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Scavenger.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Abomination",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Abomination.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Hollow Waif",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hollow-Waif.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Ashen Core",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Ashen-Core.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Dust Storm",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Dust-Storm.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Parker Barrows",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Parker-Barrows.jpg",
    "size": 30,
    "health": 14,
    "maxMeleeRange": 1
  },
  {
    "name": "Parker Barrows, Dead Man Walking",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Parker-Barrows-Dead-Man-Walking.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Mad Dog Brackett",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Mad-Dog-Brackett.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Sue",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Sue.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Convict Gunslinger",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Convict-Gunslinger.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Bandido",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Bandido.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Doc Mitchell",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Doc-Mitchell.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Wokou Raider",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Wokou-Raider.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Dead Outlaw",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Dead-Outlaw.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Pride",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Pride.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Hodgepodge Effigy",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hodgepodge-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Hodgepodge Emissary",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hodgepodge-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Von Schill",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Von-Schill.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Von Schill, Iron Heart",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Von-Schill-Iron_Heart.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Lazarus",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Lazarus.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Hannah Lovelace",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hannah-Lovelace.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Arik Schottemer",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Arik-Schottemer.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Drachen Trooper",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Drachen-Trooper.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Freikorps Librarian",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Freikorps-Librarian.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Freikorps Engineer",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Freikorps-Engineer.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Freikorps Scout",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Freikorps-Scout.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Freikorpsmann",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Freikorpsmann.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Steam Trunk",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Steam-Trunk.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Viktoria Chambers",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Viktoria-Chambers.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Viktoria Chambers, Twin Blades",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Viktoria-Chambers-Twin-Blades.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Taelor",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Taelor.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Bishop",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Bishop.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Vanessa Chambers",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Vanessa-Chambers.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Ronin",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Ronin.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Big Jake",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Big-Jake.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Student of Conflict",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Student-of-Conflict.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Desperate Mercenary",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Desperate-Mercenary.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Barbaros",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Barbaros.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Tara",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Tara.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Tara, Timeless",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Tara-Timeless.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Aionus",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Aionus.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "The Nothing Beast",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/The-Nothing-Beast.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Talos",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Talos.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Void Hunter",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Void-Hunter.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Scion of the Void",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Scion-of-the-Void.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Karina",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Karina.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Void Wretch",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Void-Wretch.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Hamelin",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hamelin.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Hamelin, The Piper",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hamelin-The-Piper.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Nix",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Nix.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Rat Catcher",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Rat-Catcher.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Obedient Wretch",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Obedient-Wretch.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Stolen",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Stolen.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Benny Wolcomb",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Benny-Wolcomb.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Rat King",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Rat-King.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Winged Plague",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Winged-Plague.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Malifaux Rat",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Malifaux-Rat.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Jack Daw",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Jack-Daw.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Jack Daw, Ensouled",
    "type": "Master",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Jack-Daw-Ensouled.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Montresor",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Montresor.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Guilty",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Guilty.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Lady Ligeia",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Lady-Ligeia.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Hans",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hans.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Johan Creedy",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Johan-Creedy.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "The Midnight Stalker",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/The-Midnight-Stalker.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Prospector",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Prospector.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Malifaux Child",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Malifaux-Child.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Necropunk",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Necropunk.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Sloth",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Sloth.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Carrion Effigy",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Carrion-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Carrion Emissary",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Carrion-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Dr. McMourning",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dr-McMourning.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Dr. McMourning, Insanitary",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dr-McMourning-Insanitary.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 1
  },
  {
    "name": "Sebastian",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Sebastian.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Rafkin",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Rafkin.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Flesh Construct",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Flesh-Construct.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Nurse",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Nurse.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Guild Autopsy",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Guild-Autopsy.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Little Gasser",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Little-Gasser.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Kentauroi",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Kentauroi.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Canine Remains",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Canine-Remains.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Zombie Chihuahua",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Zombie-Chihuahua.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Rogue Necromancy",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Rogue-Necromancy.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Molly Squidpiddge",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Molly-Squidpiddge.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Molly Squidpiddge, Chaotic Conductor",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Molly-Squidpiddge-Chaotic-Conductor.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 2
  },
  {
    "name": "Archie",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Archie.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Philip and the Nanny",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Philip-and-the-Nanny.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "The Forgotten Marshal",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/The-Forgotten-Marshal.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Rabble Riser",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Rabble-Riser.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Crooligan",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Crooligan.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Night Terror",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Night-Terror.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Necrotic Machine",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Necrotic-Machine.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Dead Rider",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dead-Rider.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Mortimer",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Mortimer.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Gravedigger",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Gravedigger.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Seamus",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Seamus.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Seamus, AKA Sebastian Baker",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Seamus-AKA-Sebastian-Baker.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 1
  },
  {
    "name": "Madame Sybelle",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Madame-Sybelle.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Bete Noire",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Bete-Noire.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Dead Doxy",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dead-Doxy.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Mourner",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Mourner.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Rotten Belle",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Rotten-Belle.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Dead Dandy",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dead-Dandy.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Copycat Killer",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Copycat-Killer.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Ashigaru",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Ashigaru.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 2
  },
  {
    "name": "Toshiro",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Toshiro-The-Daimyo.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Manos",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Manos-The-Risen.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Yin",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Yin-The-Penangalan.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Reva Cortinas",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Reva-Cortinas.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Reva Cortinas, Luminary",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Reva-Cortinas-Luminary.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Vincent St. Clair",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Vincent-St.-Clair.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Asura Roten",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Asura-Roten.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Lampad",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Lampad.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Draugr",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Draugr.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Shieldbearer",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Shieldbearer.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Restless Spirit",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Restless-Spirit.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Corpse Candle",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Corpse-Candle.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Crooked Man",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Crooked-Man.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Prof. Von Schtook",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Prof-Von-Schtook.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Prof. Von Schtook, Stargazer",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Prof-Von-Schtook-Stargazer.jpg",
    "size": 40,
    "health": 13,
    "maxMeleeRange": 2
  },
  {
    "name": "Anna Lovelace",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Anna-Lovelace.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "The Valedictorian",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/The-Valedictorian.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Student of Viscera",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Student-of-Viscera.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Student of Sinew",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Student-of-Sinew.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Student of Steel",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Student-of-Steel.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Undergraduate",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Undergraduate.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Research Assistant",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Research-Assistant.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Kirai Ankoku",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Kirai-Ankoku.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Kirai Ankoku, Envoy Of The Court",
    "type": "Master",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Kirai-Ankoku-Envoy-of-the-Court.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 0
  },
  {
    "name": "Datsue Ba",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Datsue-Ba.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Ikiryo",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Ikiryo.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Goryo",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Goryo.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Lost Love",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Lost-Love.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Onryo",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Onryo.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Gaki",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Gaki.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Seishin",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Seishin.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 1
  },
  {
    "name": "Shikome",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Shikome.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Hanged",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Hanged.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Jaakuna Ubume",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Jaakuna-Ubume.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Drowned",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Drowned.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Grave Golem",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Grave-Golem.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Bone Pile",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Bone-Pile.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Mindless Zombie",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Mindless-Zombie.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Dawn Serpent",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Dawn-Serpent.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Lust",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lust.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Shadow Effigy",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shadow-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Shadow Emissary",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shadow-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Mei Feng",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Mei-Feng.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Mei Feng, Foreman",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Mei-Feng-Foreman.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Kang",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kang.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Rail Worker",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Rail-Worker.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Forgeling",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Forgeling.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Jakob Lynch",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Jakob-Lynch.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Jakob Lynch, Dark Bet",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Hungering-Darkness.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Jakob Lynch, Dark Bet",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Jakob-Lynch-Dark-Bet.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Gwyneth Maddox",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Gwyneth-Maddox.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Mr. Graves",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Mr-Graves.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Kitty Dumont",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kitty-Dumont.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Illuminated",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Illuminated.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Mr. Tannen",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Mr-Tannen.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Hungering Darkness",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Hungering-Darkness.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Beckoner",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Beckoner.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Depleted",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Depleted.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Yasunori",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Yasunori.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Misaki Katanaka",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Misaki-Katanaka.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Misaki Katanaka, Fractured",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Misaki-Katanaka-Fractured.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Ototo",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Ototo.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Minako Rei",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Minako-Rei.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Katanaka Crime Boss",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Katanaka-Crime-Boss.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Yamaziko",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Yamaziko.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Katanaka Sniper",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Katanaka-Sniper.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Torakage",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Torakage.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Shang",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shang.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Thunder Archer",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Thunder-Archer.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Shenlong",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shenlong.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Shenlong, The Teacher",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shenlong-The-Teacher.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 2
  },
  {
    "name": "Sensei Yu",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Sensei-Yu.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "High River Monk",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/High-River-Monk.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Lotus Eater",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lotus-Eater.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Wandering River Monk",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Wandering-River-Monk.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Low River Monk",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Low-River-Monk.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Aspiring Student",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Aspiring-Student.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Charm Warder",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Charm-Warder.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Fermented River Monk",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Fermented-River-Monk.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Asami Tanaka",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Asami-Tanaka.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Asami Tanaka, Shintaku",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Asami-Tanaka-Shintaku.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Ama No Zako",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Ama-No-Zako.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Jorogumo",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Jorogumo.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Ohaguro Bettari",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Ohaguro-Bettari.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Obsidian Oni",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Obsidian-Oni.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Yokai",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Yokai.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Katashiro",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Katashiro.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Tengu",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Tengu.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Kamaitachi",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kamaitachi.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Amanjaku",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Amanjaku.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Youko Hamasaki",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Youko-Hamasaki.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Youko Hamasaki, Unseen",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Youko-Hamasaki-Unseen.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Bill Algren",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Bill-Algren.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Kabuki Warrior",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kabuki-Warrior.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Kunoichi",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kunoichi.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Geisha",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Geisha.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Chiyo Hamasaki",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Chiyo-Hamasaki.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Bunraku",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Bunraku.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Komainu",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Komainu.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Gokudo",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Gokudo.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Soul Porter",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Soul-Porter.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 2
  },
  {
    "name": "Yan Lo",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Yan-Lo.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Yan Lo, The Spirit Walker",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Yan-Lo-The-Spirit-Walker.jpg",
    "size": 40,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Izamu",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Izamu-The-Armor.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Sun Quiang",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Sun-Quiang.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Chiaki",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Chiaki-The-Beacon.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Wanyudo",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Wanyudo.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Tanuki",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Tanuki.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Lucas McCabe",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lucas-McCabe-Relic-Hunter.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Lucas McCabe, Dismounted Hunter",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lucas-McCabe-Dismounted-Hunter.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Lucas McCabe, Tomb Delver",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lucas-McCabe-Tomb-Delver.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Sidir Alchibal",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Sidir-Alchibal.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Desper LaRaux",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Desper-LaRaux.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Huckster",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Huckster.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Ruffian",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Ruffian.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Luna",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Luna.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Obsidian Statue",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Obsidian-Statue.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Fuhatsu",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Fuhatsu.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Samurai",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Samurai.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "The Lone Swordsman",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/The-Lone-Swordsman.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Ten Thunders Brother",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Ten-Thunders-Brother.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Terracotta Warrior",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Terracotta-Warrior.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Rough Rider",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Rough-Rider.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Lord Cooper",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Lord-Cooper.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Lord Cooper, Huntmaster",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Lord-Cooper-Huntmaster.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Runaway",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Runaway.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Model 9",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Model-9.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Artemis",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Artemis.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Ullr",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Ullr.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Malisaurus Rex",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Malisaurus-Rex.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 2
  },
  {
    "name": "Vatagi Huntsman",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Vatagi-Huntsman.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Crypsis Corps",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Crypsis-Corps.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Nexus",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Nexus.jpg",
    "size": 40,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Nexus, One Of Many",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Nexus-One-of-Many.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Eyes and Ears",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Eyes-and-Ears.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Dr. Meredith Stanley",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Dr-Meredith-Stanley.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Shambling Nest",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Shambling-Nest.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 2
  },
  {
    "name": "Nightsilk Creeper",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Nightsilk-Creeper.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Berserker Husk",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Berserker-Husk.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Archivist",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Archivist.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Spelleater",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Spelleater.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "English Ivan",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/English-Ivan.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "English Ivan, Double Agent",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/English-Ivan-Double-Agent.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Mr. Mordrake",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Mr-Mordrake.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Gibson DeWalt",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Gibson-DeWalt.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Eva Havenhand",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Eva-Havenhand.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Daeva",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Daeva.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Operative",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Operative.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Corvis Rook",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Corvis-Rook.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Brocken Spectre",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Brocken-Spectre.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Nocturne",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Nocturne.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Maxine Agassiz",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Maxine-Agassiz.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 1
  },
  {
    "name": "Maxine Agassiz, Monomaniacal",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Maxine-Agassiz-Monomaniacal.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Orville Agassiz",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Orville-Agassiz.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 2
  },
  {
    "name": "Kiya Manimi",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Kiya-Manimi.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Machinist",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Machinist.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Harata Ngaatoro",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Harata-Ngaatoro.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Tidecaller",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Tidecaller.jpg",
    "size": 5,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Harpooner",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Harpooner.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Dr. Beebe",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Dr-Beebe.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Calypso",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Calypso-Mk-II.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Jedza",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Jedza.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 0
  },
  {
    "name": "Jedza, Everlasting",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Jedza-Everlasting.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Sophie",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Sophie.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Mikhail XVI",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Mikhail-XVI.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Lamplighter",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Lamplighter.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Grave Goo",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Grave-Goo.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Moorwraith",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Moorwraith.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "The Damned",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/The-Damned.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Austera and Twigge",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Austera-and-Twigge.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Anya Lycarayen",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Anya-Lycarayen.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Anya Lycarayen, Rail Magnate",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Anya-Lycarayen-Rail-Magnate.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Sovereign",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Sovereign.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Winston Finnigan",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Winston-Finnigan.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Surveyor",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Surveyor.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Sand Worm",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Sand-Worm.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Cryptologist",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Cryptologist.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Intrepid Emissary",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Intrepid-Emissary.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 2
  },
  {
    "name": "Intrepid Effigy",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Intrepid-Effigy.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Mr. Ngaatoro",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Mr-Ngaatoro.jpg",
    "size": 40,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Botanist",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Botanist.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Vernon and Welles",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Vernon-and-Welles.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Jessie Halliday",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Jessie-Halliday.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Bellhop Porter",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Bellhop-Porter.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Tannenbaum",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Tannenbaum.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Alpinist",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Alpinist.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Hopeful Prospect",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Hopeful-Prospect.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Catalan Brawler",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Catalan-Brawler.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Catalan Rifleman",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Catalan-Rifleman.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Yannic Waller",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Yannic-Waller.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Kenshiro",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kenshiro-The-Tactician.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Lady Yume",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Lady-Yume.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Shojo",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Shojo.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Deacon Hillcrest",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Deacon-Hillcrest.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Drudge",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Drudge.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 1
  },
  {
    "name": "Four Winds Golem",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Four-Winds-Golem.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Backup Assistant",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Backup-Assistant.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Beau Fishbocker",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Beau-Fishbocker.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Rock Hopper",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Rock-Hopper.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 2
  },
  {
    "name": "Cavatica",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Cavatica.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Empyrean Eagle",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Empyrean-Eagle.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Jin Baccara",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Jin-Baccara.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Pearl Musgrove",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Pearl-Musgrove.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Auguste Hart",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Auguste-Hart.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Disease Containment Unit",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Disease-Containment-Unit.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Gatling Gunner",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Gatling-Gunner.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Guild Mage",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Guild-Mage.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Sly \"Six-Shots\"",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sly-Six-Shots.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Erymanthian Boar",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Erymanthian-Boar.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Dorian Crowe",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Dorian-Crowe.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Hildegard",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Hildegard.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Kaltgeist",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Kaltgeist.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Klaus Norwood",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Klaus-Norwood.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Red Cap",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Red-Cap.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "The Kurgan",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Kurgan.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Metallurgist",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Metallurgist.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Soul Battery",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Soul-Battery.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "Thirty-Three",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Thirty-Three.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Corpse Curator",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Corpse-Curator.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Enslaved Spirit",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Enslaved-Spirit.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Gwisin",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Gwisin.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Noxious Nephilim",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Noxious-Nephilim.jpg",
    "size": 50,
    "health": 11,
    "maxMeleeRange": 2
  },
  {
    "name": "White Rabbit Co.",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/White-Rabbit-Co.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Bo Peep",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bo-Peep.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Fluffernutter",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Fluffernutter.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Ruffles",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Ruffles.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Stumpy",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Stumpy.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Damian Ravencroft",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Damian-Ravencroft-Aspirant.jpg",
    "size": 30,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Damian Ravencroft, Unbound",
    "type": "Master",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Damian-Ravencroft-Unbound.jpg",
    "size": 40,
    "health": 14,
    "maxMeleeRange": 2
  },
  {
    "name": "The Puzzle Box",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/The-Puzzle-Box.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Bellaventine Thorpe",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Bellaventine-Thorpe.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Lohith",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Lohith.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 1
  },
  {
    "name": "Leger de Main",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Leger-de-Main.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Marco Bonatti",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Marco-Bonatti.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 1
  },
  {
    "name": "Dabbler",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Dabbler.jpg",
    "size": 40,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Soulstone Gamin",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Soulstone-Gamin.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 1
  },
  {
    "name": "Linh Ly",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Linh-Ly-Storyteller.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 1
  },
  {
    "name": "Linh Ly, Bibliothecary",
    "type": "Master",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Linh-Ly-Bibliothecary.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Jade Rabbit",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Jade-Rabbit.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Miya Murakami",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Miya-Murakami.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 1
  },
  {
    "name": "Map Map",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Map-Map.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Story of Thanh Giong",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Story-of-Thanh-Giong.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Story of Raijin",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Story-of-Raijin.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Story of Sun Wukong",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Story-of-Sun-Wukong.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Paper Tiger",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Paper-Tiger.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Bookkeeper",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Bookkeeper.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Calligrapher",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Calligrapher.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Harvest Man",
    "type": "Henchman",
    "faction": "Dead Man's Hand",
    "avatar": "/Malifaux/avatars/Dead Mans Hand/Harvest-Man.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Harrison Frodsham",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Harrison-Frodsham.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Harris J-5",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Harris-J5.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 2
  },
  {
    "name": "Gearling",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Gearling.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 1
  },
  {
    "name": "The Clampetts",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Clampetts-Fisherfolk.jpg",
    "size": 50,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "The Clampetts, Bally-hoo Bucket",
    "type": "Master",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/The-Clampetts-Bally-hoo-Bucket.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Bruce",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bruce.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Aunty Mel",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Aunty-Mel.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Uncle Bogg",
    "type": "Henchman",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Uncle-Bogg.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Sir Vantes",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Sir-Vantes.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Judd & Honey",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Judd-and-Honey.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Buckaroo",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Buckaroo.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Hermits",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Hermits.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Skulker Skin",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Skulker-Skin.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Harold Tull",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Harold-Tull-Artillerist.jpg",
    "size": 30,
    "health": 13,
    "maxMeleeRange": 1
  },
  {
    "name": "Harold Tull, Dead Silent",
    "type": "Master",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Harold-Tull-Dead-Silent.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Walking Cannon",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Walking-Cannon.jpg",
    "size": 40,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "King's Wall",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Kings-Wall.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 1
  },
  {
    "name": "Louisa Fusi",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Louisa-Fusi.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "John Watson",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/John-Watson.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 1
  },
  {
    "name": "Hex Bow",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Hex-Bow.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Rocketeer",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Rocketeer.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Sapper",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Sapper.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Tiri",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Tiri-The-Nomad.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Tiri, The Architect",
    "type": "Master",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Tiri-The-Architect.jpg",
    "size": 30,
    "health": 11,
    "maxMeleeRange": 0
  },
  {
    "name": "Oro Boro",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Oro-Boro.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Kett",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Kett.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "The Iron Matron",
    "type": "Henchman",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/The-Iron-Matron.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 2
  },
  {
    "name": "Parson",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Parson.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Unseelie Engine",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Unseelie-Engine.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Ancient Construct",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Ancient-Construct.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Aua Warrior",
    "type": "Minion",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Aua-Warrior.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "The First Light",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/The-First-Light.jpg",
    "size": 50,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Yaksha",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Yaksha.jpg",
    "size": 50,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Kastore",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Kastore-Awakened.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Kastore, Fervent",
    "type": "Master",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Kastore-Fervent.jpg",
    "size": 50,
    "health": 13,
    "maxMeleeRange": 0
  },
  {
    "name": "Marathine",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Marathine.jpg",
    "size": 30,
    "health": 0,
    "maxMeleeRange": 0
  },
  {
    "name": "Athorak",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Athorak.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "White Eyes",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/White-Eyes.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Cavern Nephilim",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Cavern-Nephilim.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Urnbearer",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Urnbearer.jpg",
    "size": 40,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Gwyll",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Gwyll.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Blood Vessel",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Blood-Vessel.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Koji",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Koji.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Amai",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Amai.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Kara",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Kara.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Altus Keene",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Altus-Keene.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Arun Rajput",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Arun-Rajput.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Blackbird",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Blackbird.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Boilermaker",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Boilermaker.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Ceddra",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Ceddra.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Elemental Boxer",
    "type": "Minion",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Elemental-Boxer.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Irena Keene",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Irena-Keene.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Shadowlark",
    "type": "Henchman",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Shadowlark.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Sightless Snow",
    "type": "Enforcer",
    "faction": "Arcanists",
    "avatar": "/Malifaux/avatars/Sightless-Snow.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Carnivorous Wyrdwood",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Carnivorous-Wyrdwood.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Crookskin",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Crookskin.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Delirium",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Delirium.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Delirious Thrall",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Delirious-Thrall.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Giant Leech",
    "type": "Minion",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Giant-Leech.jpg",
    "size": 50,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Ithanna",
    "type": "Henchman",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/Ithanna.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "The Leech King",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Leech-King.jpg",
    "size": 30,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "The Syzygy Sisters",
    "type": "Enforcer",
    "faction": "Neverborn",
    "avatar": "/Malifaux/avatars/The-Syzygy-Sisters.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Banying",
    "type": "Henchman",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Banying.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "K.O.T.O.",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/KOTO.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Sen",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Sen.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Story of Suzaku",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Story-of-Suzaku.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Sunless Self",
    "type": "Minion",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Sunless-Self.jpg",
    "size": 30,
    "health": 0,
    "maxMeleeRange": 0
  },
  {
    "name": "Zheng",
    "type": "Enforcer",
    "faction": "Ten Thunders",
    "avatar": "/Malifaux/avatars/Zheng.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Barrelby",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Barrelby.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Bashe",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Bashe.jpg",
    "size": 50,
    "health": 14,
    "maxMeleeRange": 0
  },
  {
    "name": "Drumstick",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Drumstick.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Habber-Dasher",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Habber-Dasher.jpg",
    "size": 30,
    "health": 3,
    "maxMeleeRange": 0
  },
  {
    "name": "Hog-Oil Salesman",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Hog-Oil-Salesman.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Hoochdini",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Hoochdini.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Hopscotch",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Hopscotch.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Jebediah",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Jebediah.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Mossbeard",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Mossbeard.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Squish and Squash",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Squish-and-Squash.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Swashbuckler",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Swashbuckler.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Swine Twirler",
    "type": "Minion",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Swine-Twirler.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Toast",
    "type": "Enforcer",
    "faction": "Bayou",
    "avatar": "/Malifaux/avatars/Toast.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Ahool",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Ahool.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Alleyway Echo",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Alleyway-Echo.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Cayce Jones",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Cayce-Jones.jpg",
    "size": 40,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Hand of Janus",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Hand-of-Janus.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Host Ducat",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Host-Ducat.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Kainhearse",
    "type": "Enforcer",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Kainhearse.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Slipstreamer",
    "type": "Minion",
    "faction": "Explorer's Society",
    "avatar": "/Malifaux/avatars/Slipstreamer.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Ashbringer",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Ashbringer.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Avel Ortega",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Avel-Ortega.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Battering Ram",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Battering-Ram.jpg",
    "size": 30,
    "health": 5,
    "maxMeleeRange": 0
  },
  {
    "name": "Bracer",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Bracer.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Camerabot",
    "type": "Minion",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Camerabot.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Clipper",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Clipper.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Commander Bura",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Commander-Bura.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Director Rodriguez",
    "type": "Henchman",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Director-Rodriguez.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "Domingo Montoya",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Domingo-Montoya.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Nightcrawler Network",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Nightcrawler-Network.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Propagandist",
    "type": "Enforcer",
    "faction": "Guild",
    "avatar": "/Malifaux/avatars/Propagandist.jpg",
    "size": 50,
    "health": 10,
    "maxMeleeRange": 0
  },
  {
    "name": "The Bad Omen",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/The-Bad-Omen.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Dr. Silas",
    "type": "Henchman",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Dr-Silas.jpg",
    "size": 40,
    "health": 12,
    "maxMeleeRange": 0
  },
  {
    "name": "Ella Mae Chesterfield",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Ella-Mae-Chesterfield.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Fumigator",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Fumigator.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Hard Stop Herbert",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Hard-Stop-Herbert.jpg",
    "size": 40,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Six Armed Six-Shooter",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Six-Armed-Six-Shooter.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Trial and Error",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Trial-and-Error.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Tunnel Rats",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Tunnel-Rats.jpg",
    "size": 50,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "Vee",
    "type": "Enforcer",
    "faction": "Outcasts",
    "avatar": "/Malifaux/avatars/Vee.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Batsch & Amalie",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Batsch-and-Amalie.jpg",
    "size": 40,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Dapperling",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Dapperling.jpg",
    "size": 30,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Ezekiel",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Ezekiel.jpg",
    "size": 30,
    "health": 6,
    "maxMeleeRange": 0
  },
  {
    "name": "The Ferryman",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/The-Ferryman.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Ghost Eater",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Ghost-Eater.jpg",
    "size": 30,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Ghost Light",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Ghost-Light.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Kari Zotiko",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Kari-Zotiko.jpg",
    "size": 30,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Keepside Strangers",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Keepside-Strangers.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "Leftovers",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Leftovers.jpg",
    "size": 50,
    "health": 8,
    "maxMeleeRange": 0
  },
  {
    "name": "Bisection",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Bisection.jpg",
    "size": 40,
    "health": 4,
    "maxMeleeRange": 0
  },
  {
    "name": "Last Bite",
    "type": "Minion",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Last-Bite.jpg",
    "size": 30,
    "health": 2,
    "maxMeleeRange": 0
  },
  {
    "name": "Old Hob",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Old-Hob.jpg",
    "size": 50,
    "health": 9,
    "maxMeleeRange": 0
  },
  {
    "name": "Oskar",
    "type": "Enforcer",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/Oskar.jpg",
    "size": 50,
    "health": 7,
    "maxMeleeRange": 0
  },
  {
    "name": "The Strange Lady",
    "type": "Henchman",
    "faction": "Resurrectionist",
    "avatar": "/Malifaux/avatars/The-Strange-Lady.jpg",
    "size": 30,
    "health": 10,
    "maxMeleeRange": 0
  }
]