import StandardDeck from '../../StandardDeck';

/**
 * Shuffles an array of cards using the Fisher-Yates algorithm
 * @param {Array} deck - The deck to shuffle
 * @returns {Array} The shuffled deck
 */
export const shuffleDeck = (deck) => {
  // Fisher-Yates shuffle algorithm
  const shuffledDeck = [...deck];
  let i = shuffledDeck.length - 1;
  for (i; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
  }
  return shuffledDeck;
};

/**
 * Creates new shuffled decks for each player
 * @returns {Object} Object containing player1Deck and player2Deck
 */
export const createShuffledDecks = () => {
  const player1Deck = shuffleDeck([...StandardDeck]);
  const player2Deck = shuffleDeck([...StandardDeck]);
  
  return { player1Deck, player2Deck };
};
