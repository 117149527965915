import React from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import FannedCards from './FannedCards';

const HandViewer = ({
  onCardClick,
  minimized = false,
  onToggleMinimize,
  game
}) => {
  const selectedPlayer = localStorage.getItem(`currentPlayer_${game.id}`);
  const playerLabel = game?.attrs?.[`player${selectedPlayer}Label`] || `Player ${selectedPlayer}`;  
  
  // If no player is selected, don't render the component
  if (!selectedPlayer) {
    return null;
  }

  // Get the correct hand cards for the selected player
  const getHandCards = () => {
    const cardFlipState = game?.attrs?.cardFlipState;
    if (!cardFlipState) return [];

    return selectedPlayer == "1"
      ? (cardFlipState.player1?.handCards || [])
      : (cardFlipState.player2?.handCards || []);
  };

  const handCards = getHandCards();

  return (
    <Paper
      elevation={3}
      style={{
        position: 'fixed',
        bottom: 20,
        left: '50%',
        transform: 'translateX(75%)',
        padding: '8px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        minWidth: '400px',
        maxWidth: '95%',
        transition: 'all 0.3s ease',
        height: minimized ? '48px' : 'auto',
        overflow: 'hidden',
        zIndex: 1000,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: minimized ? 0 : '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1">
            {playerLabel}'s Hand
          </Typography>
        </div>
        <IconButton size="small" onClick={onToggleMinimize}>
          {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
        </IconButton>
      </div>

      {!minimized && (
        <div>
          <FannedCards
            cards={handCards}
            cardSource="hand"
            isOpponent={false}
            onCardClick={onCardClick}
            selectedIndex={-1}
            emptyMessage={`${playerLabel}'s hand is empty`}
          />
        </div>
      )}
    </Paper>
  );
};

export default HandViewer;