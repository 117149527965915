export default [
    {
        title: 'Conditions',
        values: [            
            { color: '#fc19ad', text: 'Adversary', icon: '/emoji/crossed_swords.png' },
            { color: '#fc7419', text: 'Burning', icon: '/Malifaux/icons/burning.jpg' },
            { color: '#d2d914', text: 'Distracted', icon: '/Malifaux/icons/distracted.jpg' },
            { color: '#34d914', text: 'Fast', icon: '/Malifaux/icons/fast.jpg' },
            { color: '#1424d9', text: 'Focused', icon: '/emoji/muscle.png' },
            { color: '#d91414', text: 'Injured', icon: '/emoji/drop_of_blood.png' },
            { color: '#7dd914', text: 'Poison', icon: '/emoji/test_tube.png' },
            { color: '#14d9d9', text: 'Shielded', icon: '/emoji/shield.png' },
            { color: '#917f5e', text: 'Slow', icon: '/emoji/turtle.png' },
            { color: '#241a09', text: 'Staggered', icon: '/emoji/link.png' },
            { color: '#7362a3', text: 'Stunned', icon: '/emoji/face_with_head_bandage.png' },
        ],
    }    
];
