import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { cardBackImage } from '../../CardAssets';

const CardDeck = ({ 
  deck, 
  cardsToFlip,
  onFlipCards
}) => {
  const cardHeight = 300;
  const cardWidth = 220;
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="subtitle2" align="center" style={{ marginBottom: '1rem' }}>
        Deck ({deck.length})
      </Typography>
      
      {deck.length > 0 ? (
        <div>
          <img 
            src={cardBackImage} 
            alt="Card Back"
            style={{
              height: cardHeight * 0.5,
              maxWidth: cardWidth * 0.5,
              width: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
              marginBottom: '8px'
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Button 
              color="primary" 
              variant="contained" 
              onClick={onFlipCards}
              fullWidth
              size="small"
            >
              Flip {cardsToFlip}
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ 
          height: cardHeight * 0.5, 
          width: cardWidth * 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px dashed #ccc',
          borderRadius: 8 
        }}>
          <Typography variant="caption" color="textSecondary">Empty</Typography>
        </div>
      )}
    </div>
  );
};

export default CardDeck;
