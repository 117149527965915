export default {
  banner: '/Malifaux/banner.jpg',
  title: 'Malifaux 3rd Edition (Test)',
  description:
    'Malifaux is a skirmish-level miniatures wargame set in the crumbling ruins of a once-great city. Choose a crew of characters to battle your opponent in a game of tactics, strategy, and resource management.',
  disclaimer: '* War Table is not affiliated with Wyrd Games.',
  discord: '',
  rules: '',
  id: 'Malifaux',
  gameSettings: {
    system: 'Malifaux',
    width: 36,
    height: 36,
    resource1Label: 'Health',
    resource2Label: '',
    resource3Label: '',
    showArcs: false,
    name: 'Malifaux',
    backgroundImageUrl: '/backgrounds/forest_jaden.jpg',
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
