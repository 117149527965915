import React from 'react';
import Dialog from '../../../../../WTDialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ExileDialog = ({ open, onClose, exilePile, onSendToHand, onSendToDeck, onSendToDiscard }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Exile Pile</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {exilePile.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="body1" align="center">
                                No cards in exile
                            </Typography>
                        </Grid>
                    ) : (
                        exilePile.map((card, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index} style={{ textAlign: 'center' }}>
                                <img
                                    src={card.imageUrl}
                                    alt={card.label}
                                    style={{
                                        width: '120px',
                                        height: '180px',
                                        objectFit: 'contain',
                                        marginBottom: '8px'
                                    }}
                                />
                                <ButtonGroup size="small" orientation="vertical" fullWidth>
                                    <Button onClick={() => onSendToHand(index)}>
                                        Move to Hand
                                    </Button>
                                    <Button onClick={() => onSendToDeck(index)}>
                                        Move to Top of Deck
                                    </Button>
                                    <Button onClick={() => onSendToDiscard(index)}>
                                        Move to Discard
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        ))
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExileDialog;