import { sizeFor } from '../../../map/Token';

export default function (model) {
  const { width, height, shape } = sizeFor(model.size);
  const tokenAttrs = {
    label: model.alias || model.name,
    deletable: true,
    width,
    height,
    shape,
  };
  if (model.avatar) {
    tokenAttrs.imageUrl = model.avatar;
  }
  if (model.health) {
    tokenAttrs.resource1 = model.health;
    tokenAttrs.resource1Max = model.health;
  }   
  tokenAttrs.maxMeleeRange = model.maxMeleeRange || 0;
  return tokenAttrs;
}
