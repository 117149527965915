import React from 'react';
import Typography from '@mui/material/Typography';

/**
 * Renders a set of cards in a fanned layout
 */
const FannedCards = ({ 
  cards, 
  isOpponent = false, 
  selectedIndex = -1, 
  cardSource = 'current',
  onCardClick = null,
  emptyMessage = "No cards available"
}) => {
  // Base card dimensions
  const cardHeight = 300;
  const cardWidth = 220;
  
  // If no cards, render placeholder
  if (!cards || cards.length === 0) {
    const borderColor = cardSource === 'hand' ? '#ff9800' : 
                        isOpponent ? '#7b1fa2' : '#ccc';
    
    // Adjust empty container size based on card source
    const height = cardSource === 'exile' ? cardHeight * 0.25 :
                  cardSource === 'hand' ? cardHeight * 0.4 : 
                  cardHeight * 0.5;
    const width = cardSource === 'exile' ? cardWidth * 0.25 :
                 cardSource === 'hand' ? cardWidth * 0.7 : 
                 cardWidth * 0.5;
    
    return (
      <div style={{ 
        height, 
        width,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px dashed ${borderColor}`,
        borderRadius: 8,
        margin: '4px auto',
        textAlign: 'center',
        minHeight: cardSource === 'exile' ? 110 :
                  cardSource === 'hand' ? 215 : 
                  230,
        minWidth: cardSource === 'exile' ? 70 :
                 cardSource === 'hand' ? 140 : 
                 150,
      }}>
        <Typography variant="body2" color="textSecondary" style={{ width: '100%', padding: '0 8px' }}>{emptyMessage}</Typography>
      </div>
    );
  }
  
  // Adjust scale based on the card source
  const getScale = () => {
    if (isOpponent) return 0.5;
    if (cardSource === 'hand') return 0.65;
    if (cardSource === 'exile') return 0.25;
    return 0.67;
  };
  
  const scale = getScale();
  const height = cardHeight * scale;
  const width = cardWidth * scale;
  
  // Fan settings
  const fanAngle = Math.min(8, 35 / cards.length);
  const totalAngle = (cards.length - 1) * fanAngle;
  const startAngle = -totalAngle / 2;
  
  // Container style
  const containerStyle = {
    position: 'relative',
    height: height + 20,
    width: '100%',
    margin: '0 auto',
    maxWidth: width + (cards.length - 1) * (width * 0.25)
  };
  
  // Selection colors
  const selectionColor = cardSource === 'hand' ? '#ff9800' : '#f50057';

  const handleCardClick = (index) => {
    if (onCardClick) {
      onCardClick(index);
    }
  };
  
  return (
    <div style={containerStyle}>
      {cards.map((card, index) => {
        const rotateAngle = startAngle + (index * fanAngle);
        const horizontalShift = rotateAngle * 3.5;
        const isSelected = selectedIndex === index;
        
        const cardStyle = {
          position: 'absolute',
          maxWidth: width,
          width: '100%',
          transform: `rotate(${rotateAngle}deg) translateX(${horizontalShift}px)`,
          transformOrigin: '50% 100%',
          transition: 'all 0.2s ease',
          left: '50%',
          marginLeft: -width / 2,
          zIndex: index + 10,
          bottom: isSelected ? 8 : 0,
          borderRadius: '8px',
          boxShadow: isSelected ? 
            `0 0 0 2px ${selectionColor}, 0 0 10px 3px ${selectionColor}99` : 
            'none',
          cursor: onCardClick ? 'pointer' : 'default',
        };
        
        // Always use the handleCardClick function if onCardClick is provided,
        // regardless of whether this is an opponent card
        const handleClick = onCardClick ? () => handleCardClick(index) : undefined;
        
        return (
          <img 
            key={`${cardSource}-${card.suit || ''}-${card.value || ''}-${index}`}
            src={card.imageUrl}
            alt={card.label || `Card ${index + 1}`}
            style={cardStyle}
            onClick={handleClick}
            className={isSelected ? `selected-card ${cardSource}-card` : `${cardSource}-card`}
          />
        );
      })}
    </div>
  );
};

export default FannedCards;
