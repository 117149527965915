import Breachstorm from './Breachstorm';
import Bushido from './Bushido';
import ConquestFB from './ConquestFB';
import GuildBall from './GuildBall';
import Warmachine from './Warmachine';
import Warmahordes from './Warmahordes';
import Malifaux from './Malifaux';

const MODULES = {
  Malifaux,
  Breachstorm,
  Bushido,
  ConquestFB,
  GuildBall,
  Warmachine,
  Warmahordes,
};

export async function loadextensions(moduleId) {
  return MODULES[moduleId]?.extensions;
}

export async function loadData(moduleId) {
  return MODULES[moduleId]?.data;
}

export async function loadSettings(moduleId) {
  return MODULES[moduleId]?.data?.settings;
}
