import React, { Fragment } from 'react';

const plantExplosivesElements = [
  // 5 explosive tokens above the map
  {
    type: "strategyMarker",
    x: 2,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 4,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 6,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 8,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 10,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  // 5 explosive tokens below the map
  {
    type: "strategyMarker",
    x: 26,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 28,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 30,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 32,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 34,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  }
];

const raidTheVaultsElements = [
  {
    // Objective 1 on center line, 4" left of center
    type: "strategyMarker",
    x: 18 - 4,
    y: 18,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 2 on center line, 4" right of center
    type: "strategyMarker",
    x: 18 + 4,
    y: 18,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 3 - Upper Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 4 - Upper Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 5 - Lower Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 6 - Lower Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  }
];

// TODO: fix corner raid the vaults elements

const cloakAndDaggerElements = [
  // 2 strategy markers above the map
  {
    type: "strategyMarker",
    x: 2,
    y: -2,
    immobile: false,
    label: "Strategy - Concealing"
  },
  {
    type: "strategyMarker",
    x: 4,
    y: -2,
    immobile: false,
    label: "Strategy - Concealing"
  },
  // 2 strategy markers below the map
  {
    type: "strategyMarker",
    x: 32,
    y: 38,
    immobile: false,
    label: "Strategy - Concealing"
  },
  {
    type: "strategyMarker",
    x: 34,
    y: 38,
    immobile: false,
    label: "Strategy - Concealing"
  }
];

const stuffTheBallotsElements = [
  // Two on the centerline, each touching a different map edge
  {
    type: "strategyBallot",
    x: .585,
    y: 18
  },
  {
    type: "strategyBallot",
    x: 35.415,
    y: 18
  },
  // One in the center of each table quarter
  {
    type: "strategyBallot",
    x: 9,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 9,
    y: 27
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 27
  },
  // One on the centerpoint of the map
  {
    type: "strategyBallot",
    x: 18,
    y: 18
  }
];

const standardDeployElements = [
  {
    // Line 8" from the top
    type: "Line",
    x: 18,
    y: 8,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Line 8" from the bottom (at y=28)
    type: "Line",
    x: 18,
    y: 28,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Line in the middle
    type: "Line",
    x: 18,
    y: 18,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Circle in the middle, 6" diameter (3" radius)
    type: "Circle",
    x: 18,
    y: 18,
    radius: 6,
    stroke: "#ffffff",
    fill: "transparent"
  },
];

const cornerDeployElements = [
  {
    // Quarter circle in top left corner, 12" radius
    type: "Arc",
    x: 0,
    y: 0,
    radius: 12,
    startAngle: 0,
    endAngle: 90,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in top right corner, 12" radius
    type: "Arc",
    x: 36,
    y: 0,
    radius: 12,
    startAngle: 90,
    endAngle: 180,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in bottom left corner, 12" radius
    type: "Arc",
    x: 0,
    y: 36,
    radius: 12,
    startAngle: 270,
    endAngle: 360,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in bottom right corner, 12" radius
    type: "Arc",
    x: 36,
    y: 36,
    radius: 12,
    startAngle: 180,
    endAngle: 270,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Diagonal line from top-left to bottom-right
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 45,
    stroke: "#ffffff",
  },
  {
    // Diagonal line from bottom-left to top-right
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 135,
    stroke: "#ffffff",
  }
];

export default [
  {
    section: 'Malfaux Scenarios',
    description: (
      <Fragment>
        GG4 scenarios for Malifaux.
      </Fragment>
    ),
    scenarios: [
      {
        name: "Raid the Vaults - Standard",
        elements: [
          ...standardDeployElements,
          ...raidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Raid the Vaults - Corner",
        elements: [
          ...cornerDeployElements,
          ...raidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Standard",
        elements: [
          ...standardDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Corner",
        elements: [
          ...cornerDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Standard",
        elements: [
          ...standardDeployElements, ,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Corner",
        elements: [
          ...cornerDeployElements,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Standard",
        elements: [
          ...standardDeployElements,
          ...stuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Corner",
        elements: [
          ...cornerDeployElements,
          ...stuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      }
    ],
  }
];
